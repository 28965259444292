import { ReactComponent as AccessForAllSVG } from "imgs/services-info/access-for-all.svg";
import { ReactComponent as CupSVG } from "imgs/services-info/cup.svg";
import { ReactComponent as SpendFreelySVG } from "imgs/services-info/spend-freely.svg";
import { ReactComponent as AlertSVG } from "imgs/services-info/alert.svg";
import { ReactComponent as CertificateSVG } from "imgs/services-info/certificate.svg";
import { ReactComponent as CardMachineSVG } from "imgs/services-info/card-machine.svg";
import { ReactComponent as SafetyFirstSVG } from "imgs/services-info/safety-first.svg";
import { ReactComponent as BestReturnsSVG } from "imgs/services-info/best-returns.svg";
import { ReactComponent as DisciplineBuilderSVG } from "imgs/services-info/discipline-builder.svg";
import { ReactComponent as TrackMoneySVG } from "imgs/services-info/track-money.svg";
import { ReactComponent as NoMissGoalSVG } from "imgs/services-info/no-miss-goal.svg";
import { ReactComponent as CreditMeterSVG } from "imgs/services-info/credit-meter.svg";

export const serviceItems = [
  {
    label: "UPI",
    id: "upi-service",
  },
  {
    label: "credit",
    id: "credit-service",
  },
  {
    label: "investment",
    id: "investment-service",
  },
  {
    label: "financial wellbeing",
    id: "financial-service",
  },
];

export const serviceCardData = [
  {
    id: `upi-service-card`,
    title: `credit on UPI`,
    color: "#EEFF41",
    title_sub_text: `earn with every UPI transaction - it pays to pay!`,
    card_items: [
      {
        label: `access for all`,
        card_info: `digital credit card which works for all your employees’`,
        icon: <AccessForAllSVG />,
        id: "access-for-all",
      },
      {
        label: `elevate your savings`,
        card_info: `earn 2x rewards on all UPI spends`,
        icon: <CupSVG />,
        id: "elevate-savings",
      },
      {
        label: `spend freely`,
        card_info: `settle bills or manage business transaction, all in one place`,
        icon: <SpendFreelySVG />,
        id: "spend-freely",
      },
    ],
  },
  {
    id: `instant-credit`,
    title: `instant credit`,
    color: "#3DFF47",
    title_sub_text: `your money your way - always`,
    card_items: [
      {
        label: `exigency management`,
        card_info: `now handle all emergencies better, without financial worry`,
        icon: <AlertSVG />,
        id: "exigency-management",
      },
      {
        label: `best interest rates`,
        card_info: `interest rates 10% - 20% lower than market`,
        icon: <CertificateSVG />,
        id: "best-interest-rate",
      },
      {
        label: `hassle free repayments`,
        card_info: `never miss a payment using our digital repayment options`,
        icon: <CardMachineSVG />,
        id: "hassle-free-repayments",
      },
    ],
  },
  {
    id: `curated-investment`,
    title: `curated investment`,
    color: "#FF8533",
    title_sub_text: `fortify your future/wealth with best in class investments`,
    card_items: [
      {
        label: `safety first`,
        card_info: `secured investments, regulatory compliant`,
        icon: <SafetyFirstSVG />,
        id: "safety-first",
      },
      {
        label: `best-in-class returns`,
        card_info: `earn upto 9% on FD’s`,
        icon: <BestReturnsSVG />,
        id: "best-in-class-returns",
      },
      {
        label: `discipline builder`,
        card_info: `FD on salary to help you build savings discipline`,
        icon: <DisciplineBuilderSVG />,
        id: "discipline-builder",
      },
    ],
  },
  {
    id: `financial-wellbeing-suite`,
    title: `financial wellbeing suite`,
    color: "#2EE0FF",
    title_sub_text: `equip your employees to navigate their finances with confidence`,
    card_items: [
      {
        label: `track your money`,
        card_info: `gain control and insights into your finances with our money analyzer`,
        icon: <TrackMoneySVG />,
        id: "track-your-money",
      },
      {
        label: `never miss a goal`,
        card_info: `curated expert-led sessions for achieving financial goals`,
        icon: <NoMissGoalSVG />,
        id: "never-miss-goal",
      },
      {
        label: `build your credit score`,
        card_info: `promote employees’ credit growth for financial stability`,
        icon: <CreditMeterSVG />,
        id: "build-credit-score",
      },
    ],
  },
];
