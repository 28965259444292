import React from "react";
import styled, { keyframes } from "styled-components";

import Lottie from "react-lottie";
import { ReactComponent as CreditCardSVG } from "imgs/intro-cards/credit-card.svg";
import { ReactComponent as UPICashbackSVG } from "imgs/intro-cards/upi-cashback.svg";
import { ReactComponent as SalaryProgressSVG } from "imgs/intro-cards/salary-progress.svg";
import { ReactComponent as WorldTripCelebrationSVG } from "imgs/intro-cards/world-trip-celebration.svg";
import { ReactComponent as CreditMonthsSVG } from "imgs/intro-cards/credit-months.svg";
import { ReactComponent as GetMoneyBackSVG } from "imgs/intro-cards/get-money-back.svg";

import Nykaa from "imgs/brands-logo/nykaa.svg";
import EaseMyTrip from "imgs/brands-logo/ease-my-trip.svg";
import Swiggy from "imgs/brands-logo/swiggy.svg";
import Puma from "imgs/brands-logo/puma.svg";
import SwiggyInstaMart from "imgs/brands-logo/swiggy-instamart.svg";
import WestSide from "imgs/brands-logo/westside.svg";
import Croma from "imgs/brands-logo/croma.svg";

import Amazon from "imgs/brands-logo/amazon.svg";
import Zomato from "imgs/brands-logo/zomato.svg";
import Starbucks from "imgs/brands-logo/starbucks.svg";
import Myntra from "imgs/brands-logo/myntra.svg";
import Uber from "imgs/brands-logo/uber.svg";
import Flipkart from "imgs/brands-logo/flipkart.svg";
import BookMyShow from "imgs/brands-logo/book-my-show.svg";

import QRScannerLottie from "lotties/qr-scanner.json";
import GraphLottie from "lotties/graph.json";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
`;

const CreditCardIcon = styled(CreditCardSVG)`
  position: absolute;
  top: 172px;
  left: 35px;

  @media (max-width: 580px) {
    transform: scale(0.8);
    top: 120px;
    left: 10px;
  }

  @media (min-width: 581px) and (max-width: 1023px) {
    transform: scale(0.75);
  }
`;

const UPICashbackIcon = styled(UPICashbackSVG)`
  position: absolute;
  top: 354px;
  left: -30px;

  @media (max-width: 580px) {
    transform: scale(0.8);
    top: 275px;
    left: -40px;
  }
`;

const UPILottieWrapper = styled.div`
  position: absolute;
  top: 25px;
  left: -67px;
  width: 200px;
  height: 200px;
  border-radius: 30px;
  background: #ffffff;

  @media (max-width: 580px) {
    transform: scale(0.8);
    top: -10px;
  }

  @media (min-width: 581px) and (max-width: 1023px) {
    transform: scale(0.75);
  }
`;

const InvestLottieWrapper = styled.div`
  position: absolute;
  top: 158px;
  right: -40px;
  z-index: 2;
  width: 239px;
  height: 190px;
  border-radius: 24px;
  background-color: #ffffff;

  @media (max-width: 580px) {
    transform: scale(0.7);
    top: 100px;
    left: 10px;
  }

  @media (min-width: 581px) and (max-width: 1023px) {
    transform: scale(0.75);
  }
`;

const SalaryProgressIcon = styled(SalaryProgressSVG)`
  position: absolute;
  z-index: 1;
  top: 26px;
  left: -42px;

  @media (max-width: 580px) {
    transform: scale(0.8);
    top: 0px;
    left: -60px;
  }

  @media (min-width: 581px) and (max-width: 1023px) {
    transform: scale(0.75);
  }
`;

const WorldTripCelebrationIcon = styled(WorldTripCelebrationSVG)`
  position: absolute;
  top: 365px;
  right: -11px;

  @media (max-width: 580px) {
    transform: scale(0.8);
    top: 270px;
    left: 10px;
  }

  @media (min-width: 581px) and (max-width: 1023px) {
    transform: scale(0.75);
  }
`;

const CreditMonthsIcon = styled(CreditMonthsSVG)`
  position: absolute;
  top: 165px;
  right: -32px;
  z-index: 0;

  @media (max-width: 580px) {
    transform: scale(0.8);
    top: 110px;
    left: 10px;
  }

  @media (min-width: 581px) and (max-width: 1023px) {
    transform: scale(0.75);
  }
`;

const GetMoneyBackIcon = styled(GetMoneyBackSVG)`
  position: absolute;
  top: 301px;
  right: 10px;

  @media (max-width: 580px) {
    transform: scale(0.8);
    top: 225px;
    left: 10px;
  }

  @media (min-width: 581px) and (max-width: 1023px) {
    transform: scale(0.75);
  }
`;

const upiCardDefaultOptions = {
  animationData: QRScannerLottie,
  autoplay: true,
  loop: true,
};

const investCardDefaultOptions = {
  animationData: GraphLottie,
  autoplay: true,
  loop: true,
};

export const UPICardPics = () => {
  return (
    <Wrapper>
      <CreditCardIcon />
      <UPICashbackIcon />

      <UPILottieWrapper>
        <Lottie
          style={{
            position: "absolute",
            top: "-32px",
            left: "-34px",
          }}
          options={upiCardDefaultOptions}
          width={268}
          height={268}
        />
      </UPILottieWrapper>
    </Wrapper>
  );
};

export const InvestCardPics = () => {
  return (
    <Wrapper>
      <SalaryProgressIcon />
      <WorldTripCelebrationIcon />

      <InvestLottieWrapper>
        <Lottie options={investCardDefaultOptions} width={239} height={198} />
      </InvestLottieWrapper>
    </Wrapper>
  );
};

export const CreditCardPics = () => {
  return (
    <Wrapper>
      <SalaryProgressIcon />
      <CreditMonthsIcon />
      <GetMoneyBackIcon />
      <UPICashbackIcon />
    </Wrapper>
  );
};

const SlideUp = keyframes`
    from {
        transform: translateY(0);
    }
    to {
        transform: translateY(-100%);
    }
`;

const Logos = styled.div`
  width: 120px;
  height: 100%;
  overflow: hidden;
  white-space: wrap;
  box-sizing: border-box;
  position: relative;
`;

const LogosSlide = styled.div`
  box-sizing: border-box;
  animation: ${SlideUp} 10s infinite linear;
`;

const BrandIcon = styled.img`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  margin: 16px 0px;
`;

const SaveCardWrapper = styled.div`
  width: 258px;
  height: 100%;
  transform: translateX(-20%) rotate(2deg);
  mask-image: linear-gradient(
    0deg,
    transparent 0%,
    #fff 11%,
    #fff 89%,
    transparent 100%,
    #fff 100%
  );
  @media (max-width: 580px) {
    transform: translateX(-35%);
  }
`;

const LogosWrapper = styled.div`
  display: grid;
  height: 100%;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
`;

// const BoxShadow = styled.div`
//   position: absolute;
//   left: 0;
//   width: 115%;
//   height: 60px;
//   background: linear-gradient(0deg, transparent 0%, #202020 80%, #0b0b0b 100%);

//   ${({ down }) =>
//     down
//       ? css`
//           bottom: -30px;
//           left: -2px;
//           transform: rotate(180deg);
//           clip-path: polygon(4% 50%, 100% 50%, 100% 100%, -4% 100%);
//           box-shadow: 0 0 125px 10px #202020;
//         `
//       : css`
//           top: -30px;
//           clip-path: polygon(0% 50%, 96% 50%, 100% 100%, -4% 100%);
//         `}
// `;

export const SaveCardPics = () => {
  return (
    <SaveCardWrapper>
      <LogosWrapper>
        <Logos>
          <LogosSlide>
            <BrandIcon src={Amazon} alt="Amazon" />
            <BrandIcon src={Zomato} alt="Zomato" />
            <BrandIcon src={Starbucks} alt="Starbucks" />
            <BrandIcon src={Myntra} alt="Myntra" />
            <BrandIcon src={Uber} alt="Uber" />
            <BrandIcon src={Flipkart} alt="Flipkart" />
            <BrandIcon src={BookMyShow} alt="BookMyShow" />
          </LogosSlide>
          <LogosSlide>
            <BrandIcon src={Amazon} alt="Amazon" />
            <BrandIcon src={Zomato} alt="Zomato" />
            <BrandIcon src={Starbucks} alt="Starbucks" />
            <BrandIcon src={Myntra} alt="Myntra" />
            <BrandIcon src={Uber} alt="Uber" />
            <BrandIcon src={Flipkart} alt="Flipkart" />
            <BrandIcon src={BookMyShow} alt="BookMyShow" />
          </LogosSlide>
        </Logos>

        <Logos>
          <LogosSlide reverse>
            <BrandIcon src={Nykaa} alt="Nykaa" />
            <BrandIcon src={EaseMyTrip} alt="EaseMyTrip" />
            <BrandIcon src={Swiggy} alt="Swiggy" />
            <BrandIcon src={Puma} alt="Puma" />
            <BrandIcon src={SwiggyInstaMart} alt="SwiggyInstaMart" />
            <BrandIcon src={WestSide} alt="WestSide" />
            <BrandIcon src={Croma} alt="Croma" />
          </LogosSlide>
          <LogosSlide reverse>
            <BrandIcon src={Nykaa} alt="Nykaa" />
            <BrandIcon src={EaseMyTrip} alt="EaseMyTrip" />
            <BrandIcon src={Swiggy} alt="Swiggy" />
            <BrandIcon src={Puma} alt="Puma" />
            <BrandIcon src={SwiggyInstaMart} alt="SwiggyInstaMart" />
            <BrandIcon src={WestSide} alt="WestSide" />
            <BrandIcon src={Croma} alt="Croma" />
          </LogosSlide>
        </Logos>
      </LogosWrapper>
      {/* <BoxShadow />
      <BoxShadow down /> */}
    </SaveCardWrapper>
  );
};
