import styled, { css } from "styled-components";

export const Separator = styled.div`
  height: ${({ height }) =>
    height &&
    css`
      ${height}
    `};

  @media (max-width: 768px) {
    height: ${({ tabheight }) =>
      tabheight &&
      css`
        ${tabheight}
      `};
  }
  @media (max-width: 594px) {
    height: ${({ mobileheight }) =>
      mobileheight &&
      css`
        ${mobileheight}
      `};
  }
`;

const EmployerCommonTextCss = css`
  font-size: 40px;
  font-style: normal;
  font-weight: 900;
  line-height: 52px;
  letter-spacing: -0.8px;
  text-align: center;

  .sentence-break {
    display: none;
  }
  @media (max-width: 594px) {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.48px;

    .sentence-break {
      display: block;
    }
  }
`;

export const EmployerTitle = styled.div`
  ${EmployerCommonTextCss}
  & > .colored-text {
    color: #eeff41;
  }
  & > .normal-text {
    color: #fff;
  }
`;

export const FlexView = styled.div(
  ({
    $flexDirection,
    $flexWrap,
    $alignItems,
    $justifyContent,
    $width,
    $flexGap,
    $margin,
  }) => {
    return `
    display: flex;
    flex-direction: ${$flexDirection || "row"};
    flex-wrap: ${$flexWrap ? "wrap" : "nowrap"};
    align-items: ${$alignItems || "flex-start"};
    justify-content: ${$justifyContent || "flex-start"};
    width: ${$width || "auto"};
    gap: ${$flexGap || "unset"};
  `;
  }
);

export const InputBox = styled.input`
  background: transparent;
  outline: none;
  border: 1px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  width: 320px;
  padding: 10px;
  text-align: center;
  color: #fff;
  text-align: center;
  font-size: 40px;
  font-style: normal;
  font-weight: 900;
  line-height: 52px;
  letter-spacing: -0.8px;
  @media (max-width: 968px) {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.48px;
    width: 240px;
  }
`;
