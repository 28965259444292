import { useParallax } from "react-scroll-parallax";
import styled from "styled-components";

import { ReactComponent as BulletPointStarSVG } from "imgs/bullet-point-star.svg";

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

  background-image: linear-gradient(136deg, #202020 0%, #0b0b0b 100%),
    linear-gradient(136deg, #eeff4130 0%, #3d41fa30 60.05%);
  background-clip: padding-box, border-box;
  background-origin: border-box;
  border: 2px solid transparent;
  border-radius: 32px;

  @media (max-width: 580px) {
    border-bottom: 0;
  }

  @media (max-width: 1022px) {
    background-image: linear-gradient(180deg, #202020 0%, #0b0b0b 100%),
      linear-gradient(180deg, #eeff4130 0%, #3d41fa30 60.05%);

    :first-child {
      border: none;
      padding-right: 4px;
    }
  }
`;

const Title = styled.div`
  width: 78px;
  height: fit-content;
  position: absolute;
  z-index: 2;

  display: flex;
  justify-content: center;
  align-items: flex-end;

  @media (max-width: 580px) {
    width: 100%;
    height: 45px;
    top: 0;
  }

  @media (min-width: 581px) and (max-width: 1023px) {
    width: 100%;
    height: 45px;
    top: 0;
  }

  @media (min-width: 1024px) {
    bottom: 50px;
  }
`;

const TransformText = styled.span`
  color: rgba(255, 255, 255, 0.4);
  font-family: Noto Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;

  @media (min-width: 1024px) {
    text-orientation: mixed;
    writing-mode: tb-rl;
    transform: rotate(180deg);
  }
`;

const ContentContainer = styled.div`
  padding-left: 40px;
  height: 100%;
  display: grid;
  grid-template-areas: "a b";
  grid-template-columns: 1fr 1fr;
  grid-gap: 8px;
  align-items: center;

  @media (max-width: 580px) {
    display: flex;
    flex-direction: column-reverse;
    margin: 0px 16px 0;
    padding: 0;
  }

  @media (min-width: 581px) and (max-width: 1022px) {
    padding: 0;
    margin: 40px;
  }

  @media (min-width: 1024px) {
    grid-template-columns: 1fr 1fr;
    padding: 0 24% 0 8%;
  }
`;

const LeftCard = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  color: #ffffff;
  gap: 40px;
  flex-direction: column;
  align-items: flex-start;

  @media (max-width: 580px) {
    transform: translateY(-35%);
    gap: 15px;
  }

  @media (min-width: 581px) and (max-width: 1023px) {
    padding-top: 80px;
  }

  @media (min-width: 1024px) {
    justify-content: center;
  }
`;

const RightCard = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.2s ease-out;

  @media (max-width: 580px) {
    height: 100dvh;
    transform: scale(0.6) translateY(-14%);
  }

  @media (min-width: 581px) and (max-width: 1023px) {
    transform: scale(0.75) translate(22px, -68px);
  }

  @media (min-width: 1024px) and (max-width: 1100px) {
    justify-content: center;
    transform: scale(0.8) translateX(8px);
  }

  @media (min-width: 1024px) {
    justify-content: center;
  }
`;

const TitleWrapper = styled.div`
  max-width: 354px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: ${({ gap }) => gap}px;

  @media (max-width: 768px) {
    gap: 8px;
  }

  @media (max-width: 425px) {
    :first-child {
      margin-top: 10px;
    }
  }
`;

const CompoTitle = styled.p`
  color: #ffffff;
  font-family: Noto Sans;
  font-style: normal;
  font-weight: 700;
  margin: 0;
  text-align: left;
  display: flex;
  gap: 16px;
  align-items: flex-start;
  justify-content: flex-start;
  transition: all 0.2s ease-out;

  @media (max-width: 580px) {
    font-size: 26px;
    line-height: 32px;
    letter-spacing: -0.56px;
    gap: 4px;
  }

  @media (min-width: 581px) and (max-width: 1024px) {
    font-size: 40px;
    line-heigh: 48px;
    letter-spacing: -0.72px;
    gap: 8px;
  }

  @media (min-width: 1025px) {
    font-size: 54px;
    line-height: 72px;
    letter-spacing: -1.08px;
    gap: 16px;
  }
`;

const TitleContent = styled.p`
  text-align: left;
  margin: 0;
  color: #fff;
  font-family: Noto Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  transition: all 0.2s ease-out;

  @media (max-width: 580px) {
    font-size: 13px;
    line-height: 18px;
  }

  @media (min-width: 581px) and (max-width: 1023px) {
    font-size: 16px;
    line-heigh: 20px;
  }
`;

const ImageContainer = styled.div`
  min-width: 131px;
  max-width: 240px;
  width: 100%;
  max-height: 460px;
  height: 100%;
  position: relative;

  border-radius: 24px;
  background: ${({ bgColor }) =>
    bgColor || "linear-gradient(153deg, #eeff41 0%, #3d41fa 96.05%)"};

  @media (max-width: 580px) {
    max-height: 360px;
    width: 200px;
    max-width: 100%;
  }
`;

const BulletPointWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 580px) {
    transform: scale(0.75);
  }
`;

const BulletPoint = styled(BulletPointStarSVG)`
  width: 24px;
  height: 24px;

  @media (max-width: 580px) {
    transform: scale(0.75);
  }
`;

const ListItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;

  color: #fff;
  font-family: Noto Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;

  @media (max-width: 580px) {
    font-size: 13px;
    line-height: 14px;
  }

  @media (min-width: 581px) and (max-width: 1023px) {
    font-size: 16px;
    line-heigh: 16px;
  }
`;

const IntroCardView = ({
  parallaxState,
  cardData,
  componentData,
  imageContainerFiller,
  imageContainerBgColor,
}) => {
  const parallax = useParallax(parallaxState || {});
  const { cardName, showCard } = cardData || {};
  const { title, titleSuffix, content, bulletPoints } = componentData || {};

  return (
    <Wrapper ref={parallax.ref}>
      {!showCard && (
        <ContentContainer>
          <LeftCard>
            <TitleWrapper gap={8}>
              <CompoTitle>
                <span style={{ color: "#EEFF41" }}>{title}</span>
                {titleSuffix}
              </CompoTitle>
              <TitleContent style={{ color: "#ffffff" }}>
                {content}
              </TitleContent>
            </TitleWrapper>

            <TitleWrapper gap={24}>
              {bulletPoints?.map((points) => {
                return (
                  <ListItem key={points}>
                    <BulletPointWrapper>
                      <BulletPoint />
                    </BulletPointWrapper>
                    {points}
                  </ListItem>
                );
              })}
            </TitleWrapper>
          </LeftCard>

          <RightCard>
            <ImageContainer bgColor={imageContainerBgColor}>
              {imageContainerFiller}
            </ImageContainer>
          </RightCard>
        </ContentContainer>
      )}

      {showCard && (
        <Title>
          <TransformText>{cardName}</TransformText>
        </Title>
      )}
    </Wrapper>
  );
};

export default IntroCardView;
