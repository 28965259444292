import React, { useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import AboutUs from "screens/about-us";
import ForEmployer from "screens/for-employer";
import Header from "screens/header";
import Home from "screens/home-page";

const AppRoutes = () => {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (window.location.hash === "#contact") {
      // replace setShowModal with your actual function to show the modal
      setShowModal(true);
    }
  }, []);

  const handleShowModal = (value) => {
    if (!value) {
      document.body.style.overflow = "initial";
    } else {
      document.body.style.overflow = "hidden";
    }

    setShowModal(value);
  };

  return (
    <>
      <Header showModal={showModal} setShowModal={handleShowModal} />
      <Routes>
        <Route
          path="/"
          exact
          element={
            <Home showModal={showModal} setShowModal={handleShowModal} />
          }
          component={updateTracking}
        />
        <Route
          path="/about-us"
          exact
          element={
            <AboutUs showModal={showModal} setShowModal={handleShowModal} />
          }
          component={updateTracking}
        />
        <Route
          path="/for-employer"
          exact
          element={
            <ForEmployer showModal={showModal} setShowModal={handleShowModal} />
          }
          component={updateTracking}
        />
      </Routes>
    </>
  );
};

const updateTracking = () => {
  window.ga("send", "pageview", {
    page: window.location.pathname,
  });
};

export default AppRoutes;
