import { ReactComponent as TheEconomicTime } from "imgs/media-logos/economic-times.svg";
import { ReactComponent as FinancialExpress } from "imgs/media-logos/financial-express.svg";
import { ReactComponent as BusinessLine } from "imgs/media-logos/business-line.svg";
import { ReactComponent as TimeOfIndia } from "imgs/media-logos/times-of-india.svg";
import { ReactComponent as Etnow } from "imgs/media-logos/etnow.svg";
import { ReactComponent as BW } from "imgs/media-logos/bw.svg";
import styled from "styled-components";

const StyledTheEconomicTime = styled(TheEconomicTime)`
  width: 178px;
  height: 15px;

  @media (max-width: 768px) {
    width: 124px;
    height: 11px;
  }
`;

const StyledFinancialExpress = styled(FinancialExpress)`
  width: 178px;
  height: 21px;
  flex-shrink: 0;

  @media (max-width: 768px) {
    width: 140px;
    height: 13px;
  }
`;

const StyledBusinessLine = styled(BusinessLine)`
  width: 178px;
  height: 21px;
  flex-shrink: 0;

  @media (max-width: 768px) {
    width: 140px;
    height: 14px;
  }
`;

const StyledTimeOfIndia = styled(TimeOfIndia)`
  width: 178px;
  height: 14px;
  flex-shrink: 0;

  @media (max-width: 768px) {
    width: 124px;
    height: 11px;
  }
`;

const StyledEtnow = styled(Etnow)`
  width: 178px;
  height: 28px;
  flex-shrink: 0;

  @media (max-width: 768px) {
    width: 124px;
    height: 18px;
  }
`;

const StyledBW = styled(BW)`
  width: 178px;
  height: 21px;
  flex-shrink: 0;

  @media (max-width: 768px) {
    width: 124px;
    height: 11px;
  }
`;

export const mediaConfig = [
  {
    background: "white",
    img: <StyledTheEconomicTime />,
    link: "https://economictimes.indiatimes.com/tech/funding/salaryse-secures-5-million-in-seed-funding-from-peak-xv-surge-pravega/articleshow/106658113.cms?from=mdr",
  },
  {
    background: "#005E8E",
    img: <StyledFinancialExpress />,
    link: "https://www.financialexpress.com/business/industry-salaryses-credit-on-upi-app-to-go-live-in-a-few-months-3362374/",
  },
  {
    background: "white",
    img: <StyledBusinessLine />,
    link: "https://www.thehindubusinessline.com/companies/salaryse-secures-525-million-in-seed-funding-from-peak-xv-surge-pravega/article67722475.ece",
  },
  {
    background: "white",
    img: <StyledTimeOfIndia />,
    link: "https://timesofindia.indiatimes.com/city/kolkata/salaryse-raises-525-million-in-seed-funding-for-fintech-application/articleshow/107276255.cms",
  },
  {
    background: "#BE1F24",
    img: <StyledEtnow />,
    link: "https://www.etnownews.com/videos/shows/salaryse-secures-5-million-in-seed-funding-led-by-peak-xv-surge-startup-central-video-106936034",
  },
  {
    background: "white",
    img: <StyledBW />,
    link: "https://bwdisrupt.businessworld.in/article/SalarySe-Secures-5-25-Mn-From-Peak-XV-s-Surge-Pravega-Ventures-/10-01-2024-505418/",
  },
];
