import React from "react";
import styled, { css } from "styled-components";
import { ReactComponent as CircleX } from "../imgs/circle-x.svg";
import { ReactComponent as Home } from "../imgs/home.svg";
import { ReactComponent as Emoji } from "../imgs/emoji.svg";
import { ReactComponent as EmployeeIcon } from "../imgs/employers-Icon.svg";
import { ReactComponent as HelpCircle } from "../imgs/help-circle.svg";
import { ReactComponent as PrivacyIcon } from "../imgs/privacy-icons.svg";
import { ReactComponent as MenuStroke } from "../imgs/menu-stroke.svg";
import { Separator } from "./common-styled-component";
import { useLocation, useNavigate } from "react-router-dom";

const Container = styled.div`
  position: fixed;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(25px);
  z-index: 999;
  opacity: 0;
  transition: all 0.5s ease-in-out;
  visibility: hidden;

  @media (max-width: 594px) {
    ${({ showMenu }) =>
      showMenu &&
      css`
        visibility: visible;
        left: 0;
        opacity: 1;
      `}
  }
`;

const ContentWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;

const CircleXIcon = styled(CircleX)`
  position: absolute;
  top: 24px;
  right: 24px;
  cursor: pointer;
`;

const MenuStrokeImage = styled(MenuStroke)`
  position: absolute;
  bottom: 0;
  left: -95px;
  width: 420.481px;
  height: 424.327px;
`;

const Button = styled.button`
  z-index: 1000;
  display: flex;
  max-width: 295px;
  width: 100%;
  padding: 14px 0px;
  justify-content: center;
  align-items: center;
  border: transparent;
  position: absolute;
  bottom: 15px;
  left: 50%;
  transform: translate(-50%, -50%);

  color: #e3e5e5;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.4px;
  background: #3d41fa;
  border-radius: 8px;

  :hover {
    transition: all 200ms ease-in-out;
    cursor: pointer;
    background: white;
    color: black;
  }
`;

const MenuWrapper = styled.div`
  padding: 0px 24px;
`;

const Menu = styled.div`
  display: flex;
  gap: 8px;
  justify-content: start;
  align-items: center;
  padding: 14px 0;
  color: #fff;
  ${({ active }) =>
    active &&
    css`
      color: #eeff41;
      path {
        stroke: #eeff41;
      }
    `}
`;

const Text = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.4px;
`;

const OPTIONS = [
  { title: "Home", component: Home, path: "/" },
  { title: "For employer", component: EmployeeIcon, path: "/for-employer" },
  { title: "About us", component: Emoji, path: "/about-us" },
  { title: "Privacy policy", component: PrivacyIcon, path: "privacy-policy" },
  {
    title: "Terms and condition",
    component: HelpCircle,
    path: "terms-conditions",
  },
];

const MobileMenu = ({ showMenu, handleMenuToggle, setShowModal }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location || {};

  const handleClickMenu = (path) => {
    handleMenuToggle();
    if (path === "privacy-policy") {
      window.open("https://salaryse.com/PrivacyPolicy.html", "_blank");

      return;
    } else if (path === "terms-conditions") {
      window.open("https://salaryse.com/TermsConditions.html", "_blank");

      return;
    }
    navigate(path);
  };

  return (
    <Container showMenu={showMenu}>
      <ContentWrapper>
        <Separator height="72px" />
        <MenuWrapper>
          {OPTIONS.map(({ title, component: Icon, path }, index) => (
            <Menu
              key={index}
              active={pathname === path}
              onClick={() => {
                handleClickMenu(path);
              }}
            >
              <Icon />
              <Text>{title}</Text>
            </Menu>
          ))}
        </MenuWrapper>
        <Button
          onClick={() => {
            setShowModal(true);
            handleMenuToggle();
          }}
        >
          contact us
        </Button>
        <CircleXIcon onClick={handleMenuToggle} />
        <MenuStrokeImage />
      </ContentWrapper>
    </Container>
  );
};

export default MobileMenu;
