import { Parallax } from "react-scroll-parallax";
import { FlexView } from "screens/common-styled-component";
import styled from "styled-components";

const EmployerIntroCardsWrapper = styled(FlexView)`
  width: 100%;
  padding: 50px 0;

  @media (max-width: 768px) {
    padding: 52px 0;
  }

  @media (max-width: 594px) {
    padding: 48px 0;
  }
`;

const SectionContainer = styled.section`
  width: 100%;
  height: 1920px;
  padding: 46px 0;

  @media (max-width: 768px) {
    height: 1839px;
    padding: 40px 0;
  }

  @media (max-width: 594px) {
    height: 2185px;
    padding: 42px 0;
  }
`;

const IntroCardSection = styled.div`
  position: sticky;
  top: 130px;
  width: 100%;
  overflow: hidden;

  ::-webkit-scrollbar {
    display: none;
  }

  @media (min-width: 1024px) {
    width: calc(100% + 40px);
  }

  @media (max-width: 768px) {
    top: 80px;
  }
`;

const ParallaxWrapper = styled(Parallax)`
  position: sticky;
  overflow: hidden;
  top: 150px;
  transition: all 0.2s ease-out;
  border-radius: 24px;

  @media (max-width: 1023px) {
    top: 40px;
    background-image: linear-gradient(136deg, #202020 0%, #0b0b0b 100%),
      linear-gradient(136deg, #eeff4130 0%, #3d41fa30 60.05%);
    background-clip: padding-box, border-box;
    background-origin: border-box;
    border: 2px solid transparent;
    border-radius: 32px;
    padding-right: 4px;
  }

  @media (min-width: 1024px) {
    height: 644px;
    transform: translateX(40px);
    border-radius: 0;
  }
`;

const IntroCardContainer = styled.div`
  width: 100%;
  height: 100%;
  margin: auto;
  position: relative;
  height: 613px;

  @media (max-width: 768px) {
    height: 680px;
  }

  @media (max-width: 594px) {
    height: 680px;
  }
`;

export {
  EmployerIntroCardsWrapper,
  SectionContainer,
  IntroCardSection,
  ParallaxWrapper,
  IntroCardContainer,
};
