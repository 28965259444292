import styled, { keyframes } from "styled-components";

export const TopSectionBackgroundImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const Container = styled.div`
  width: 100%;
  scroll-behavior: smooth;
  background: #000;
`;

export const TopSection = styled.div`
  position: relative;
  height: 548px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    height: 542px;
  }
  @media (max-width: 425px) {
    height: 424px;
  }
`;

export const Text = styled.div`
  color: ${({ color }) => color || "#fff"};
  text-align: center;
  font-family: Noto Sans;
  font-size: 72px;
  font-style: normal;
  font-weight: 900;
  line-height: 90px;
  letter-spacing: -1.44px;
  z-index: 10;
  cursor: default;
  padding: 0 20px;

  @media (max-width: 768px) {
    font-size: 48px;
    line-height: 44px;
    letter-spacing: -0.96px;
  }

  @media (max-width: 425px) {
    font-size: 32px;
    letter-spacing: -0.64px;
  }
`;

export const SeparatorHeight = styled.div`
  height: ${({ height }) => height};

  @media (max-width: 768px) {
    height: ${({ tabHeight }) => tabHeight};
  }

  @media (max-width: 425px) {
    height: ${({ mobileHeight }) => mobileHeight};
  }
`;

export const SubText = styled.p`
  color: #fff;
  text-align: center;
  font-family: Noto Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  z-index: 10;
  line-height: 30px;
  cursor: default;
  padding: 0 20px;
  @media (max-width: 768px) {
    font-size: 16px;
  }
  @media (max-width: 425px) {
    font-size: 14px;
    line-height: 20px;
  }
`;

export const SalarySaySpam = styled.span`
  color: #fff;
  font-family: Noto Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  cursor: default;
  line-height: 30px;
  @media (max-width: 768px) {
    font-size: 16px;
  }
  @media (max-width: 425px) {
    font-size: 14px;
    line-height: 20px;
  }
`;

export const PhoneContainer = styled.div`
  height: 740px;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  align-items: flex-end;
  margin-top: -84px;
  overflow: hidden;
  position: relative;

  @media (max-width: 768px) {
    height: 388.86px;
    margin-top: -29px;
  }
  @media (max-width: 425px) {
    height: 263.86px;
  }
`;

export const BoxShadow = styled.div`
  position: absolute;
  bottom: -32px;
  z-index: 5;
  width: 100%;
  height: 148px;
  background: linear-gradient(to top, #000000 0%, #00000090 100%);
  filter: blur(20px);

  @media (max-width: 768px) {
    height: 110px;
  }

  @media (max-width: 425px) {
    width: 343px;
    height: 80px;
  }
`;

export const BottomContainer = styled.div`
  width: 680px;
  position: relative;
  @media (max-width: 768px) {
    width: 506px;
    bottom: -20px;
  }
  @media (max-width: 425px) {
    width: 343px;
  }
`;

export const keyFeamePh1 = keyframes`
  0% { 
    transform: translateX(-50%) translateY( 0px);
    left: 50%;
  }
   
  100% { 
    transform: translateX(0) translateY( -586px); 
    left: 0%;
  }
`;

export const keyFeamePh2 = keyframes`
  0% { 
    transform: translateX(-50%) translateY( 0px);
  }
   
  100% { 
    transform: translateX(-50%) translateY( -586px); 
  }
`;

export const keyFeamePh3 = keyframes`
  0% { 
    transform: translateX(-50%) translateY( 0px);
    left: 50%;
  }
  
  100% { 
    transform: translateX(-100%) translateY(-586px); 
    left: 100%;
  }
`;

export const PhoneSvgFirst = styled.img`
  position: absolute;
  width: 294.387px;
  height: 585.565px;
  display: ${({ showAnimation }) => (showAnimation ? "block" : "none")};
  left: 0;
  z-index: 3;
  bottom: -663.565px;
  animation: ${keyFeamePh1} 1.5s forwards;

  @media (max-width: 768px) {
    width: 219.058px;
    height: 435.73px;
  }
  @media (max-width: 425px) {
    width: 148.492px;
    height: 295.366px;
  }
`;

export const PhoneSvgSecond = styled.img`
  position: absolute;
  width: 294.387px;
  height: 585.565px;
  display: ${({ showAnimation }) => (showAnimation ? "block" : "none")};
  left: 50%;
  z-index: 4;
  left: 50%;
  bottom: -615.565px;
  animation: ${keyFeamePh2} 1.5s forwards;

  @media (max-width: 768px) {
    width: 219.058px;
    height: 435.73px;
  }
  @media (max-width: 425px) {
    width: 148.492px;
    height: 295.366px;
  }
`;

export const ChampionsWrapper = styled.div`
  padding: 0 120px;

  @media (max-width: 968px) {
    padding: 0 80px;
  }
  @media (max-width: 768px) {
    padding: 0 40px;
  }
  @media (max-width: 594px) {
    padding: 0 16px;
  }
`;

export const PhoneSvgThird = styled.img`
  position: absolute;
  width: 294.387px;
  height: 585.565px;
  display: ${({ showAnimation }) => (showAnimation ? "block" : "none")};
  right: 0;
  z-index: 3;
  bottom: -663.565px;
  animation: ${keyFeamePh3} 1.5s forwards;

  @media (max-width: 768px) {
    width: 219.058px;
    height: 435.73px;
  }
  @media (max-width: 425px) {
    width: 148.492px;
    height: 295.366px;
  }
`;

export const ComingSoonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const CommingSoonText = styled.div`
  color: #fff;
  font-family: Noto Sans;
  font-size: 30px;
  font-weight: 700;
  line-height: 38px;
  text-align: center;
  @media (max-width: 425px) {
    font-size: 20px;
    line-height: 30px;
  }
`;

export const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #3e42fa;
  color: #fff;
  text-align: center;
  font-family: Noto Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  padding: 16px 52px;
  border-radius: 8px;

  transition: all 200ms ease-in-out;

  :hover {
    cursor: pointer;
    background: white;
    color: black;
  }
`;

export const CircularGradient = styled.div`
  position: absolute;
  top: calc(${({ y }) => y}px - 250px);
  left: calc(${({ x }) => x}px - 250px);
  width: 500px;
  height: 500px;
  background: linear-gradient(153deg, #eeff41 0%, #3d41fa 96.05%);
  border-radius: 50%;
  opacity: 0.5;
  filter: blur(120px);
  box-shadow: 0 0 20px 20px rgba(0, 0, 0, 0.5);
  z-index: 3;

  @media (max-width: 768px) {
    width: 400px;
    height: 400px;
    top: calc(${({ y }) => y}px - 220px);
    left: calc(${({ x }) => x}px - 220px);
  }
  @media (max-width: 425px) {
    width: 248px;
    height: 276px;
    filter: blur(65px);

    top: calc(${({ y }) => y}px - 138px);
    left: calc(${({ x }) => x}px - 138px);
  }
`;

export const IntroNBannerWrapper = styled.div`
  padding: 0 40px;

  @media (max-width: 425px) {
    padding: 0 16px;
  }
`;
