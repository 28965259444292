import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import sugmya from "../imgs/sugmya.png";
import yesBank from "../imgs/yes-bank.png";
import liquiloans from "../imgs/liquiloans-logo.png";
import almondz from "../imgs/almondz-logo.png";
import { Separator } from "./common-styled-component";
import {
  AddressContactContainer,
  AddressContainer,
  BankingContainer,
  BankingText,
  Company,
  CompanyResourceContainer,
  CompanyResourceText,
  ContactContainer,
  FooterContainer,
  GstText,
  HeadingText,
  Image,
  ImageContainer,
  LogoGstContainer,
  SalarySeLogo,
  Section,
} from "./footer-styles";

const Footer = ({ setShowModal }) => {
  const location = useLocation();
  const { pathname } = location || {};
  const navigate = useNavigate();

  return (
    <FooterContainer>
      <Section>
        <LogoGstContainer>
          <SalarySeLogo />
          <GstText>
            Ⓒ 2023 Critical Path Technologies Private Limited
          </GstText>{" "}
          <GstText>GST: 06AAKCC6358C1ZP</GstText>
        </LogoGstContainer>
        <CompanyResourceContainer>
          <Company>
            <HeadingText>company</HeadingText>
            <CompanyResourceText
              isdisabled={pathname === "/for-employer"}
              onClick={() =>
                pathname !== "/for-employer" && navigate("/for-employer")
              }
            >
              for-employer
            </CompanyResourceText>
            <CompanyResourceText
              isdisabled={pathname === "/about-us"}
              onClick={() => pathname !== "/about-us" && navigate("/about-us")}
            >
              about us
            </CompanyResourceText>
            <CompanyResourceText onClick={() => setShowModal(true)}>
              contact us
            </CompanyResourceText>
          </Company>
          <Company>
            <HeadingText>resources</HeadingText>
            <CompanyResourceText
              onClick={() =>
                window.open("https://salaryse.com/PrivacyPolicy.html", "_blank")
              }
            >
              privacy policy
            </CompanyResourceText>
            <CompanyResourceText
              onClick={() =>
                window.open(
                  "https://salaryse.com/TermsConditions.html",
                  "_blank"
                )
              }
            >
              terms and conditions
            </CompanyResourceText>
            <CompanyResourceText
              onClick={() =>
                window.open(
                  "https://salaryse.com/upi-roles-and-responsibilities.html",
                  "_blank"
                )
              }
            >
              UPI Roles & Responsibilities
            </CompanyResourceText>
          </Company>
        </CompanyResourceContainer>
      </Section>
      <Separator height="40px" tabheight="24px" />
      <BankingContainer>
        <BankingText>our banking and lending partners</BankingText>
        <Separator height="20px" />
        <ImageContainer>
          <Image src={yesBank} width="92px" height="34px" />
          <Image src={liquiloans} width="56px" height="32px" />
          <Image src={sugmya} width="66px" height="30px" />
          <Image src={almondz} width="176px" height="30px" />
        </ImageContainer>
      </BankingContainer>
      <Separator height="40px" tabheight="24px" />
      <AddressContactContainer>
        <AddressContainer>
          <BankingText>registered address:</BankingText>
          <BankingText>
            3rd Floor, Orchid Center, Golf course road, Sector 53, Gurugram,
            Haryana, 122002
          </BankingText>
        </AddressContainer>
        <ContactContainer>
          <AddressContainer>
            <BankingText>contact No:</BankingText>
            <BankingText>+91-9818889429</BankingText>
          </AddressContainer>
          <AddressContainer>
            <BankingText>email:</BankingText>
            <BankingText>contact@salaryse.com</BankingText>
          </AddressContainer>
        </ContactContainer>
      </AddressContactContainer>
      <Separator height="40px" tabheight="24px" />
    </FooterContainer>
  );
};

export default Footer;
