import React, { useEffect, useRef, useState } from "react";

import { useFollowPointer } from "../hooks/useFollowPointer";
import { Banner } from "./banner";
import Champions from "./champions";
import { Separator } from "./common-styled-component";
import ContactUsModal from "./ContactUsModal";
import Footer from "./footer";
import {
  BottomContainer,
  Button,
  CircularGradient,
  ComingSoonContainer,
  CommingSoonText,
  Container,
  TopSection,
  PhoneContainer,
  IntroNBannerWrapper,
  PhoneSvgFirst,
  PhoneSvgSecond,
  PhoneSvgThird,
  SalarySaySpam,
  SeparatorHeight,
  SubText,
  Text,
  BoxShadow,
  ChampionsWrapper,
  TopSectionBackgroundImage,
} from "./home-page-styles";
import IntroCardModule from "./intro-cards";
import PhoneSvg1 from "imgs/phone-1.svg";
import PhoneSvg2 from "imgs/phone-2.svg";
import PhoneSvg3 from "imgs/phone-3.svg";
import BgStrokes from "../imgs/background-strokes.svg";
import { useLocation } from "react-router-dom";

const Home = (props) => {
  const { showModal, setShowModal } = props;
  const [isScrolled, setscrolled] = useState(false);
  const containerRef = useRef(null);
  const { x, y } = useFollowPointer(containerRef);
  const elementRef = useRef(null);
  const [showAnimation, setShowAnimation] = useState(false);
  const location = useLocation();
  const { pathname } = location || {};

  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => setscrolled(true), 100); // Set timeout duration as needed
  }, [pathname, setscrolled]);

  useEffect(() => {
    if (!elementRef.current) return;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (!showAnimation) {
            setShowAnimation(
              entry.isIntersecting && entry.intersectionRatio >= 0.7
            );
          }
        });
      },
      {
        threshold: [0.5, 0.55, 0.6, 0.65, 0.7, 0.75, 0.8, 0.85, 0.9, 0.95, 1.0],
      }
    );

    observer.observe(elementRef.current);

    return () => observer.disconnect();
  }, [elementRef, showAnimation]);

  useEffect(() => {
    if (!elementRef.current) return;

    const elementRect = elementRef.current?.getBoundingClientRect();
    const isInitiallyVisible =
      elementRect.top >= 0 && elementRect.bottom <= window.innerHeight;

    if (isInitiallyVisible) {
      setShowAnimation(true);
    }
  }, [elementRef]);

  if (!isScrolled) {
    return (
      <div style={{ background: "black", width: "100%", height: "100dvh" }} />
    );
  }

  return (
    <>
      <Container>
        <div>
          <TopSection id="some" ref={containerRef}>
            <TopSectionBackgroundImage
              src={BgStrokes}
              alt="top-section-background-image"
            />
            <Text>unleash the</Text>
            <SeparatorHeight height="8px" tabHeight="16px" mobileHeight="0px" />
            <Text color="#EEFF41">power of your salary</Text>
            <SeparatorHeight
              height="64px"
              tabHeight="24px"
              mobileHeight="12px"
            />
            <SubText>
              with India’s first Credit-on-UPI platform exclusively for{" "}
              <SalarySaySpam>Salaried Employees </SalarySaySpam>
            </SubText>
            <CircularGradient x={x} y={y} />
          </TopSection>
          <PhoneContainer
            id="my-element"
            ref={elementRef}
            showAnimation={showAnimation}
          >
            <BottomContainer>
              <PhoneSvgFirst
                src={PhoneSvg1}
                showAnimation={showAnimation}
                alt="phone-svg-1"
              />
              <PhoneSvgSecond
                src={PhoneSvg2}
                showAnimation={showAnimation}
                alt="phone-svg-2"
              />
              <PhoneSvgThird
                src={PhoneSvg3}
                showAnimation={showAnimation}
                alt="phone-svg-3"
              />
            </BottomContainer>
            <BoxShadow />
          </PhoneContainer>
          <Banner />
        </div>
        <Separator height="120px" tabheight="100px" mobileheight="48px" />
        <IntroNBannerWrapper>
          <IntroCardModule />
          <Separator height="120px" tabheight="100px" mobileheight="48px" />
        </IntroNBannerWrapper>
        <ChampionsWrapper>
          <Champions setShowModal={setShowModal} />
        </ChampionsWrapper>
        <Separator height="120px" tabheight="100px" mobileheight="48px" />
        <ComingSoonContainer>
          <CommingSoonText>coming soon to your company</CommingSoonText>
          <Separator height="32px" tabheight="32px" mobileheight="24px" />
          <Button onClick={() => setShowModal(true)}>contact us</Button>
        </ComingSoonContainer>
        <Separator height="100px" tabheight="84px" mobileheight="40px" />
        <Footer setShowModal={setShowModal} />
      </Container>
      <ContactUsModal showModal={showModal} setShowModal={setShowModal} />
    </>
  );
};

export default Home;
