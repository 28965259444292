import React, { useEffect, useState } from "react";

import cross from "../imgs/cross.svg";
import { ReactComponent as SalarySe } from "../imgs/salary-se.svg";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import axios from "axios";

const formDefaultData = {
  name: "",
  companyEmail: "",
  phone: "",
  employer: "",
  message: "",
  designation: "",
  whatsappFlag: true,
};

function ContactUsModal(props) {
  const { showModal, setShowModal } = props;
  const [showError, setShowError] = useState(false);
  const [formDemoReply, setFormDemoReply] = useState("yes");
  const [isEmployee, setIsEmployee] = useState(true);
  const [companyEmailInvalid, setCompanyEmailInvalid] = useState(false);
  useEffect(() => {
    import("./ContactUsModal.css");
  }, []);
  const [data, setData] = useState(formDefaultData);

  const FORM_HEADER = {
    headers: {
      Accept: "*/*",
      "Content-Type": "application/json",
    },
  };

  async function storeEmployeeAPi() {
    const API_URL = "https://api.salaryse.com/gw/v1/save/lead";

    try {
      const response = await axios.post(API_URL, data, FORM_HEADER);

      return response;
    } catch (err) {}
  }

  async function storeEmployerAPi() {
    const API_URL = "https://api.salaryse.com/gw/v1/save/lead";

    try {
      const response = await axios.post(API_URL, data, FORM_HEADER);

      return response;
    } catch (err) {}
  }

  const handleBackdropClick = (e) => {
    if (e.target.classList.contains("main-container")) {
      setShowModal(false);
    }
  };

  const submitForm = () => {
    if (isEmployee) {
      const { name, phone, employer, designation } = data;

      if (name && phone.length === 10 && employer && designation) {
        data.demoFlag = formDemoReply === "yes" ? true : false;
        data.roleType = "EMPLOYEE";
        storeEmployeeAPi();
        setShowError(false);
        toast.success("Thank you for showing interest in SalarySe");
        //setShowModal(false);
        setData(formDefaultData);
      } else {
        setShowError(true);
      }
    } else {
      const { name, companyEmail, employer, designation } = data;

      const companyEmailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      if(companyEmail && !companyEmail.match(companyEmailPattern)) {
        setCompanyEmailInvalid(true);
        setShowError(true);
      } else {
        if (name && employer && designation) {
          data.demoFlag = formDemoReply === "yes" ? true : false;
          data.roleType = "EMPLOYER";
          storeEmployerAPi();
          setShowError(false);
          toast.success("Thank you for showing interest in SalarySe");
          //setShowModal(false);
          setData(formDefaultData);
        } else {
          setShowError(true);
        }
      }
    }
  };

  const handleFormChange = (val) => {
    if (val === isEmployee) return null;

    setIsEmployee(val);
    setData(formDefaultData);
    setFormDemoReply("yes");
    setShowError(false);
  };

  return (
    <>
      <ToastContainer />
      <div
        className={`${
          showModal ? "main-container ContainerOpen" : "main-container"
        }`}
        onClick={handleBackdropClick}
      >
        <div
          className={`${
            showModal ? "main-modal-container open" : "main-modal-container"
          }`}
        >
          <div className="modal-header">
            <div className="cross" onClick={() => setShowModal(false)}>
              <img src={cross} alt="img" />
            </div>
          </div>
          <div className="modal-container">
            <div className="modal-content-left">
              <SalarySe width="100px" />
              <span className="title-get">get in touch</span>
              <span className="title-about">
                we partner with employers to provide financial products to their
                employees. Show interest and we will work with your employer to
                bring SalarySe to you.
              </span>
              <div className="bottom-container">
                <div className="img-container"></div>
                <div className="contact-container">
                  <div className="phone-details">
                    <div>contact no:</div>
                    <div>+91-9818889429</div>
                  </div>
                  <div className="email-details">
                    <div>email:</div>
                    <div>contact@salaryse.com</div>
                  </div>
                </div>
                <div />
              </div>
            </div>
            <div className="modal-content-right">
              <div className="input-container">
                <div className="switcher">
                  <div
                    className={!isEmployee ? "employee" : "employer"}
                    onClick={() => handleFormChange(true)}
                  >
                    for employee
                  </div>
                  <div
                    className={isEmployee ? "employee" : "employer"}
                    onClick={() => handleFormChange(false)}
                  >
                    for employer
                  </div>
                </div>
                <div
                  className={`width-85 ${
                    showError && !data.name && "show-error"
                  }`}
                >
                  <div className="flex-space-between ">
                    <div className="title">
                      name <span className="red">*</span>
                    </div>
                    {showError && !data.name && (
                      <div className="red">(required)</div>
                    )}
                  </div>
                  <div className={`input-box `}>
                    <input
                      type="text"
                      value={data.name}
                      onChange={(event) => {
                        setData({ ...data, name: event.target.value });
                      }}
                    />
                  </div>
                </div>
                {isEmployee ? (
                  <div
                    className={`width-85 ${
                      showError &&
                      (!data.phone ||
                      data.phone.length !== 10) &&
                      "show-error"
                    }`}
                  >
                    <div className="flex-space-between ">
                      <div className="title">
                        contact no: <span className="red">*</span>
                      </div>
                      {showError && (
                        <>
                          {!data.phone && <div className="red">(required)</div>}
                          {data.phone && data.phone.length !== 10 && (
                            <div className="red">(invalid)</div>
                          )}
                        </>
                      )}
                    </div>
                    <div className="input-box">
                      <strong>+91</strong>
                      <input
                        type="number"
                        value={data.phone}
                        onChange={(event) => {
                          const input = event.target.value;
                          setData({ ...data, phone: input });
                        }}
                      />
                    </div>
                  </div>
                ) : (
                  <div
                    className={`width-85 ${
                      showError && !data.name && "show-error"
                    }`}
                  >
                    <div className="flex-space-between ">
                      <div className="title">
                        company email <span className="red">*</span>{" "}
                      </div>
                      {showError && (!data.companyEmail || companyEmailInvalid) && (
                        <div className="red">(required)</div>
                      )}
                    </div>
                    <div className={`input-box `}>
                      <input
                        type="companyEmail"
                        value={data.companyEmail}
                        onChange={(event) => {
                          setData({ ...data, companyEmail: event.target.value });
                        }}
                      />
                    </div>
                  </div>
                )}
                <div
                  className={`width-85 ${
                    showError && !data.employer && "show-error"
                  }`}
                >
                  <div className="flex-space-between ">
                    <div className="title">
                      {isEmployee ? "employer" : "company name"}{" "}
                      <span className="red">*</span>
                    </div>
                    {showError && !data.employer && (
                      <div className="red">(required)</div>
                    )}
                  </div>
                  <div className="input-box">
                    <input
                      type="text"
                      value={data.employer}
                      onChange={(event) => {
                        setData({ ...data, employer: event.target.value });
                      }}
                    />
                  </div>
                </div>
                <div
                  className={`width-85 ${
                    showError && !data.name && "show-error"
                  }`}
                >
                  <div className="flex-space-between ">
                    <div className="title">
                      designation <span className="red">*</span>
                    </div>
                    {showError && !data.designation && (
                      <div className="red">(required)</div>
                    )}
                  </div>
                  <div className={`input-box `}>
                    <input
                      type="text"
                      value={data.designation}
                      onChange={(event) => {
                        setData({ ...data, designation: event.target.value });
                      }}
                    />
                  </div>
                </div>
                <div className="width-85">
                  <div className="flex-space-between ">
                    <div className="title">message (optional)</div>
                  </div>
                  <div className="input-box-area">
                    <textarea
                      value={data.message}
                      onChange={(event) => {
                        setData({ ...data, message: event.target.value });
                      }}
                    />
                  </div>
                </div>
                <div className="concent-input">
                  <div className="demo-concent">
                    {" "}
                    do you want to book demo with us?{" "}
                  </div>
                  <div className="demo-reply">
                    <div
                      className={
                        formDemoReply === "yes" ? "active-response" : "response"
                      }
                      onClick={() => setFormDemoReply("yes")}
                    >
                      yes
                    </div>
                    <div
                      className={
                        formDemoReply === "no" ? "active-response" : "response"
                      }
                      onClick={() => setFormDemoReply("no")}
                    >
                      no
                    </div>
                  </div>
                </div>

                <div className="btn-container">
                  <div className="btn-container-border"></div>
                  <div className="check-box">
                    <input
                      checked={data.whatsappFlag}
                      onChange={() =>
                        setData({ ...data, whatsappFlag: !data.whatsappFlag })
                      }
                      type="checkbox"
                      id="vehicle1"
                      name="vehicle1"
                      value="Bike"
                    />
                    <div className="check-box-label" for="vehicle1">
                      receive updates over {isEmployee ? "whatsapp" : "email"}
                    </div>
                  </div>
                  <div
                    className="submit-container"
                    onClick={() => submitForm()}
                  >
                    <div className="submit-btn">submit</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactUsModal;
