const getParallaxStateOneDataPoints = (elementVPTop) => {
  const firstParallaxDesktopScreen = {
    translateX: window.innerWidth >= 1024 && [81, 8],
    translateY: window.innerWidth < 1024 && [86.6, 6],
    startScroll: 2850,
    endScroll: 3220,
  };

  const firstParallaxTabletScreen = {
    translateX: window.innerWidth >= 1024 && [81, 8],
    translateY: window.innerWidth < 1024 && [81, 10],
    startScroll: 2550,
    endScroll: 3050,
  };

  const firstParallaxMobileScreen = {
    translateX: window.innerWidth >= 1024 && [81, 8],
    translateY: window.innerWidth < 1024 && [83, 9],
    startScroll: elementVPTop,
    endScroll: elementVPTop + 800,
  };

  return {
    firstParallaxDesktopScreen,
    firstParallaxTabletScreen,
    firstParallaxMobileScreen,
  };
};

const getParallaxStateTwoDataPoints = (elementVPTop) => {
  const secondParallaxDesktopScreen = {
    translateX: window.innerWidth >= 1024 && [89, 16],
    translateY: window.innerWidth < 1024 && [93, 12.4],
    startScroll: 3250,
    endScroll: 3850,
  };

  const secondParallaxTabletScreen = {
    translateX: window.innerWidth >= 1024 && [89, 16],
    translateY: window.innerWidth < 1024 && [90, 20],
    startScroll: 3100,
    endScroll: 3550,
  };

  const secondParallaxMobileScreen = {
    translateX: window.innerWidth >= 1024 && [89, 16],
    translateY: window.innerWidth < 1024 && [91, 18],
    startScroll: elementVPTop + 850,
    endScroll: elementVPTop + 1250,
  };

  return {
    secondParallaxDesktopScreen,
    secondParallaxTabletScreen,
    secondParallaxMobileScreen,
  };
};

export { getParallaxStateOneDataPoints, getParallaxStateTwoDataPoints };
