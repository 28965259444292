import React from "react";
import styled, { css } from "styled-components";

import chevronDown from "../../imgs/arrow-down.svg";
import DropdownContainer from "./dropdown-container";

const RelativeWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const DropdownWrapper = styled.div`
  display: flex;
  width: 320px;
  padding: 16px;
  align-items: center;
  gap: 16px;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: rgba(255, 255, 255, 0.7);

  &:hover {
    cursor: pointer;
  }
  @media (max-width: 968px) {
    width: 240px;
  }
`;

const ImageWrapper = styled.div`
  position: absolute;
  right: 14px;
  top: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  align-items: center;
`;

const ListWrapper = styled.div`
  display: ${(props) => (props.listOpen ? "block" : "none")};
  z-index: 3;
  background-color: white;
  position: absolute;
  width: 100%;
  height: 220px;
  overflow: scroll;
  left: 0;
  top: 60px;
  border-radius: 8px;
`;

const List = styled.div`
  height: 40px;

  &:hover {
    cursor: pointer;
    background: rgba(0, 0, 0, 0.1);
  }
`;

const TextWrapper = styled.div`
  color: #000;
  font-size: 16px;
  font-style: normal;
  text-align: left;
  font-weight: 400;
  line-height: 24px;
  padding: 8px 16px;
  letter-spacing: -0.32px;
  ${({ selected }) =>
    selected &&
    css`
      font-weight: 900;
      letter-spacing: -0.32px;
    `}
`;

const ImageIcon = styled.img`
  transform: ${(props) => (props.listOpen ? "rotate(180deg)" : "rotate(0)")};
`;

const ListItem = ({ selectedItem, option, onClick }) => (
  <List onClick={onClick}>
    <TextWrapper selected={selectedItem && selectedItem.id === option.id}>
      {option.value}
    </TextWrapper>
  </List>
);

const Dropdown = ({ options, defaultItem, onChange }) => {
  return (
    <DropdownContainer defaultItem={defaultItem} onChange={onChange}>
      {({
        showOptionList,
        selectedItem,
        handleListDisplay,
        handleOptionClick,
        listRef,
        dropdownRef,
      }) => (
        <RelativeWrapper>
          <DropdownWrapper ref={listRef} onClick={handleListDisplay}>
            {selectedItem && <div>{selectedItem.value}</div>}
            <ImageWrapper>
              <ImageIcon
                listOpen={showOptionList}
                src={chevronDown}
                height={24}
                width={24}
              />
            </ImageWrapper>
          </DropdownWrapper>
          <ListWrapper ref={dropdownRef} listOpen={showOptionList}>
            {options?.map((option) => (
              <ListItem
                selectedItem={selectedItem}
                option={option}
                onClick={() => handleOptionClick(option)}
              />
            ))}
          </ListWrapper>
        </RelativeWrapper>
      )}
    </DropdownContainer>
  );
};

export default Dropdown;
