import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import backgroundStroke from "../imgs/about-us-background-stroke.svg";
import salarySe from "../imgs/salary-se.svg";
import SubmitForm from "./submit-form/submit-form";

import { Separator } from "./common-styled-component";
import ContactUsModal from "./ContactUsModal";
import Footer from "./footer";
import { Typewriter } from "react-simple-typewriter";
import {
  CircularGradient,
  IntroNBannerWrapper,
  SeparatorHeight,
  TopSection,
} from "./home-page-styles";
import EmployerBenefits from "./employer-benefits";
import EmployerSafety from "./employer-safety";
import ServicesProvidedScreen from "./services-info";
import EmployerIntroCards from "./employer-intro-cards";
import MediaCardsCarousal from "./media-cards";
import BenefitCalculator from "./benefit-calculator";
import { useLocation } from "react-router-dom";

const Container = styled.div`
  background-color: black;
  color: white;
  width: 100%;
`;

const Banner = styled.div`
  height: calc(100vh - 320px);
  padding: 120px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(${backgroundStroke});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media (max-width: 968px) {
    height: calc(100vh - 240px);
    padding: 80px 0;
  }

  @media (max-width: 808px) {
    height: calc(100vh - 160px);
    padding: 40px 0;
  }

  @media (max-width: 694px) {
    height: calc(100vh - 80px);
    padding: 16px 0;
  }
`;

const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.div`
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 64px;
  letter-spacing: -1.2px;
  text-align: center;

  @media (max-width: 1200px) {
    font-size: 48px;
    line-height: 72px;
    letter-spacing: -0.96px;
  }

  @media (max-width: 594px) {
    font-size: 24px;
    line-height: 30px;
    letter-spacing: -0.48px;
  }
`;

const Description = styled.div`
  color: #eeff41;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 80px;
  font-style: normal;
  font-weight: 900;
  line-height: 120px; /* 150% */
  letter-spacing: -1.6px;

  @media (max-width: 768px) {
    font-size: 68px;
    line-height: 80px;
    letter-spacing: -1.36px;
  }
  @media (max-width: 594px) {
    font-size: 32px;
    line-height: 30px;
    letter-spacing: -0.64px;
  }
`;

const SecondSection = styled(TopSection)`
  height: 720px;
  overflow: hidden;
  @media (max-width: 768px) {
    height: 640px;
  }
`;

const IntroText = styled.div`
  display: flex;
  color: #fff;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 133.333% */
  letter-spacing: -0.48px;
  align-items: center;
  justify-content: center;
  gap: 32px;

  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.32px;
  }
  @media (max-width: 594px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

const EmployerText = styled.div`
  width: 490px;
  color: #fff;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 133.333% */
  letter-spacing: -0.48px;

  @media (max-width: 768px) {
    width: 360px;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.32px;
  }
  @media (max-width: 594px) {
    width: 240px;
    font-size: 16px;
    line-height: 24px;
  }
`;

const GradientDiv1 = styled.div`
  width: 103px;
  height: 2px;
  background: linear-gradient(
    270deg,
    #fff -26.21%,
    rgba(255, 255, 255, 0) 100%
  );
  @media (max-width: 768px) {
    width: 68px;
    height: 1px;
    flex-shrink: 0;
  }
`;

const GradientDiv2 = styled.div`
  width: 103px;
  height: 2px;
  background: linear-gradient(90deg, #fff -26.21%, rgba(255, 255, 255, 0) 100%);
  @media (max-width: 768px) {
    width: 68px;
    height: 1px;
    flex-shrink: 0;
  }
`;

const SalarySeIcon = styled.img`
  width: 490px;
  z-index: 5;
  @media (max-width: 768px) {
    width: 320px;
  }
  @media (max-width: 594px) {
    width: 280px;
  }
`;

const ForEmployer = (props) => {
  const { showModal, setShowModal } = props;
  const containerRef = useRef(null);
  const [isScrolled, setscrolled] = useState(false);
  const location = useLocation();
  const { pathname } = location || {};

  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => setscrolled(true), 100); // Set timeout duration as needed
  }, [pathname, setscrolled]);

  if (!isScrolled) {
    return (
      <div style={{ background: "black", width: "100%", height: "100dvh" }} />
    );
  }

  return (
    <Container>
      <Banner>
        <ContentWrapper>
          <Title>want to enhance employee</Title>
          <SeparatorHeight height="16px" tabHeight="16px" mobileHeight="16px" />
          <Description>
            <Typewriter
              words={[
                "retention?",
                "engagement?",
                "financial wellbeing?",
                "productivity?",
              ]}
              typeSpeed={200}
              deleteSpeed={100}
              delaySpeed={1000}
              cursor={true}
              loop={true}
            />
          </Description>
        </ContentWrapper>
      </Banner>
      <SecondSection id="try" ref={containerRef}>
        <IntroText>
          <GradientDiv1 />
          <div>introducing</div>
          <GradientDiv2 />
        </IntroText>
        <Separator height="64px" tabheight="48px" mobileheight="48px" />
        <SalarySeIcon alt="salary se" src={salarySe} />
        <Separator height="64px" tabheight="48px" mobileheight="48px" />
        <EmployerText>
          India’s first digital card for employees, with credit on UPI
          functionality
        </EmployerText>
        <CircularGradient
          x={window.innerWidth / 2}
          y={window.innerHeight / 3}
        />
      </SecondSection>
      <ServicesProvidedScreen />
      <Separator height="70px" tabheight="20px" />
      <IntroNBannerWrapper>
        <EmployerIntroCards />
      </IntroNBannerWrapper>
      <Separator height="70px" tabheight="20px" />
      <EmployerBenefits />
      <Separator height="180px" tabheight="120px" mobileheight="96px" />
      <BenefitCalculator />
      <Separator height="180px" tabheight="120px" mobileheight="96px" />
      {/* <ClientVideo />
      <Separator height="120px" tabheight="104px" mobileheight="96px" /> */}
      <EmployerSafety />
      <Separator height="180px" tabheight="120px" mobileheight="96px" />
      <MediaCardsCarousal />
      <SubmitForm />

      <Footer setShowModal={setShowModal} />
      <ContactUsModal showModal={showModal} setShowModal={setShowModal} />
    </Container>
  );
};

export default ForEmployer;
