import ServiceInfoTitle from "screens/services-info/title";
import {
  EmployerIntroCardsWrapper,
  IntroCardContainer,
  IntroCardSection,
  ParallaxWrapper,
  SectionContainer,
} from "./intro-cards-styled";
import { ReactComponent as ThunderSVG } from "imgs/line-icons/thunder.svg";
import IntroCardView from "screens/intro-cards/view";
import { ParallaxProvider } from "react-scroll-parallax";
import { SaveCardPics } from "screens/intro-cards/card-based-pics";
import { useCallback, useEffect, useRef, useState } from "react";
import { getScreenWidth, getScrollY } from "utils/index";
import { EmployeeAdvancesPic, ReimbursementsPic } from "./card-based-pics";
import {
  getParallaxStateOneDataPoints,
  getParallaxStateTwoDataPoints,
} from "./utils";

const EmployerIntroCards = () => {
  const [eleEntered, setEleEntered] = useState();
  const [parallaxStateOne, setParallaxStateOne] = useState({});
  const [parallaxStateTwo, setParallaxStateTwo] = useState({});
  const [showManagement, setShowManagement] = useState(false);
  const [showReimbursement, setShowReimbursement] = useState(true);
  const [showEmployeeAdvances, setShowEmployeeAdvances] = useState(true);

  const elementVPTop = useRef(0);

  const handleProgress = useCallback(() => {
    const screenWidth = getScreenWidth();
    const topOffset = getScrollY();
    if (screenWidth <= 594) {
      // mobile
      if (topOffset <= 2650 && topOffset > 2500) {
        setShowReimbursement(true);
      } else if (topOffset >= 2651 && topOffset <= 3400) {
        setShowManagement(false);
        setShowReimbursement(false);
        setShowEmployeeAdvances(true);
      } else if (topOffset >= 3401 && topOffset <= 3490) {
        setShowManagement(true);
        setShowEmployeeAdvances(true);
      } else if (topOffset >= 3491 && topOffset <= 3850) {
        setShowManagement(true);
        setShowReimbursement(false);
        setShowEmployeeAdvances(false);
      } else if (topOffset >= 3851 && topOffset <= 4250) {
        setShowReimbursement(true);
        setShowEmployeeAdvances(false);
      }
    } else if (screenWidth > 594 && screenWidth <= 768) {
      // tablet
      if (topOffset > 2200 && topOffset < 2550) {
        setShowReimbursement(true);
      } else if (topOffset >= 2551 && topOffset <= 3050) {
        setShowManagement(false);
        setShowReimbursement(false);
        setShowEmployeeAdvances(true);
      } else if (topOffset >= 3051 && topOffset <= 3550) {
        setShowManagement(true);
        setShowReimbursement(false);
        setShowEmployeeAdvances(false);
      } else if (topOffset >= 3551 && topOffset <= 4050) {
        setShowReimbursement(true);
        setShowEmployeeAdvances(false);
      }
    } else {
      if (topOffset > 2230 && topOffset < 2850) {
        setShowReimbursement(true);
      } else if (topOffset >= 2850 && topOffset <= 3220) {
        setShowManagement(false);
        setShowReimbursement(false);
      } else if (topOffset >= 3221 && topOffset <= 3250) {
        setShowManagement(true);
        setShowReimbursement(false);
        setShowEmployeeAdvances(true);
      } else if (topOffset >= 3251 && topOffset <= 3850) {
        setShowReimbursement(false);
        setShowEmployeeAdvances(false);
      } else if (topOffset >= 3851 && topOffset <= 4251) {
        setShowReimbursement(true);
        setShowEmployeeAdvances(false);
      }
    }
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleProgress);
    window.addEventListener("resize", handleProgress);

    const sectionElement = document.getElementById(
      "employer-intro-card-section"
    );

    elementVPTop.current = sectionElement.offsetTop;

    return () => {
      window.removeEventListener("scroll", handleProgress);
      window.removeEventListener("resize", handleProgress);
    };
  }, [handleProgress]);

  useEffect(() => {
    if (eleEntered) {
      const {
        firstParallaxDesktopScreen,
        firstParallaxTabletScreen,
        firstParallaxMobileScreen,
      } = getParallaxStateOneDataPoints(elementVPTop.current);

      const {
        secondParallaxDesktopScreen,
        secondParallaxTabletScreen,
        secondParallaxMobileScreen,
      } = getParallaxStateTwoDataPoints(elementVPTop.current);

      setParallaxStateOne(
        window.innerWidth >= 1024
          ? firstParallaxDesktopScreen
          : window.innerWidth > 594
          ? firstParallaxTabletScreen
          : firstParallaxMobileScreen
      );
      setParallaxStateTwo(
        window.innerWidth >= 1024
          ? secondParallaxDesktopScreen
          : window.innerWidth > 594
          ? secondParallaxTabletScreen
          : secondParallaxMobileScreen
      );
    }
  }, [eleEntered]);

  return (
    <EmployerIntroCardsWrapper $flexDirection="column" $alignItems="center">
      <ServiceInfoTitle
        title="fully integrated business suite to save time, hassle and money"
        subText="maximise efficiency - minimise complexity"
        subTextIcon={<ThunderSVG />}
      />

      <ParallaxProvider>
        <SectionContainer id="employer-intro-card-section">
          <IntroCardSection>
            <ParallaxWrapper onEnter={() => setEleEntered(true)}>
              <IntroCardContainer>
                <IntroCardView
                  componentData={{
                    title: "R&R management",
                    content:
                      "enable the world of rewarding experience for your employees",
                    bulletPoints: [
                      <div>
                        upto <b>60% off</b> across <b>300+ brands</b>
                      </div>,
                      <div>
                        <b>give rewards</b> - give relevant rewards which they
                        enjoy!
                      </div>,
                      <div>
                        <b>curated deals</b> only for your employees’
                      </div>,
                    ],
                  }}
                  imageContainerFiller={<SaveCardPics />}
                  imageContainerBgColor="linear-gradient(153deg, #FF42F7 0%, #3D41FA 96.05%);"
                  cardData={{
                    showCard: showManagement,
                    cardName: "R&R management",
                  }}
                />
                <IntroCardView
                  parallaxState={parallaxStateOne}
                  componentData={{
                    title: "reimbursement",
                    content:
                      "Streamline your reimbursements with our automated system for fast, hassle-free payouts!",
                    bulletPoints: [
                      <div>
                        <b>submit claims</b> in real-time
                      </div>,
                      <div>
                        <b>categorise spends and track</b> against available
                        limit
                      </div>,
                      <div>
                        <b>prevent fraud</b> using verified information through
                        UPI
                      </div>,
                      <div>
                        avail <b>upto 45 days interest free credit</b> on
                        reimbursable spends
                      </div>,
                    ],
                  }}
                  imageContainerFiller={<ReimbursementsPic />}
                  imageContainerBgColor="linear-gradient(153deg, #FF42F7 0%, #3D41FA 96.05%);"
                  cardData={{
                    showCard: showReimbursement,
                    cardName: "reimbursement",
                  }}
                />
                <IntroCardView
                  parallaxState={parallaxStateTwo}
                  componentData={{
                    title: "employee advances",
                    content:
                      "simplify your employee advance proposition with our integrated solution",
                    bulletPoints: [
                      <div>
                        <b>unlock working capital</b>
                      </div>,
                      <div>
                        <b>enable higher limits</b> through our multiple
                        offerings
                      </div>,
                      <div>
                        <b>Zero risk</b>, even in the event of attrition or
                        absconding
                      </div>,
                    ],
                  }}
                  imageContainerFiller={<EmployeeAdvancesPic />}
                  imageContainerBgColor="linear-gradient(153deg, #FF42F7 0%, #3D41FA 96.05%);"
                  cardData={{
                    showCard: showEmployeeAdvances,
                    cardName: "employee advances",
                  }}
                />
              </IntroCardContainer>
            </ParallaxWrapper>
          </IntroCardSection>
        </SectionContainer>
      </ParallaxProvider>
    </EmployerIntroCardsWrapper>
  );
};

export default EmployerIntroCards;
