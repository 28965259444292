import { useCallback, useEffect, useState } from "react";

export const useFollowPointer = (
  containerRef,
  a = window.innerWidth / 1.5,
  b = window.innerHeight / 2.5
) => {
  const [position, setPosition] = useState({
    x: a,
    y: b,
  });

  const handleMouseMove = useCallback(
    (e) => {
      const container = containerRef.current;

      if (container) {
        const rect = container.getBoundingClientRect();
        const x = Math.max(
          0,
          Math.min(e.clientX - rect.left, container.clientWidth)
        );
        const y = Math.max(
          0,
          Math.min(e.clientY - rect.top, container.clientHeight)
        );

        setPosition({ x, y });
      }
    },
    [containerRef]
  );

  const handleMouseEnter = useCallback(() => {
    document.addEventListener("mousemove", handleMouseMove);
  }, [handleMouseMove]);

  const handleMouseLeave = useCallback(() => {
    document.removeEventListener("mousemove", handleMouseMove);
  }, [handleMouseMove]);

  useEffect(() => {
    const container = containerRef.current;

    if (container && window.innerWidth >= 768) {
      container.addEventListener("mouseenter", handleMouseEnter);
      container.addEventListener("mouseleave", handleMouseLeave);
    }

    return () => {
      if (container) {
        container.removeEventListener("mouseenter", handleMouseEnter);
        container.removeEventListener("mouseleave", handleMouseLeave);
      }

      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, [containerRef, handleMouseEnter, handleMouseLeave, handleMouseMove]);

  if (window.innerWidth <= 768) {
    return {
      x: a,
      y: b,
    };
  }

  return position;
};
