import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import styled, { css } from "styled-components";

import backgroundStroke from "../imgs/about-us-background-stroke.svg";
import { ReactComponent as ArrowCircleRight } from "../imgs/arrow-circle-right.svg";
import { ReactComponent as CheckSquare } from "../imgs/check-square.svg";
import { ReactComponent as DoubleVector } from "../imgs/double-vector.svg";
import mohit from "../imgs/Mohit.png";
import piyush from "../imgs/Piyush.png";
import saumeet from "../imgs/Saumeet.png";
import { ReactComponent as SingleVector } from "../imgs/single-vector.svg";
import Champions from "./champions";
import { Separator } from "./common-styled-component";
import ContactUsModal from "./ContactUsModal";
import Footer from "./footer";

const DoubleVectorIcon = styled(DoubleVector)`
  position: absolute;
  top: 0px;

  @media (max-width: 330px) {
    width: 272px;
  }
`;

const SingleVectorIcon = styled(SingleVector)`
  position: absolute;
  top: 0px;

  @media (max-width: 330px) {
    width: 272px;
  }
`;

const Container = styled.div`
  background-color: black;
  color: white;
  width: 100%;
`;

const Banner = styled.div`
  height: 300px;
  padding: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(${backgroundStroke});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media (max-width: 968px) {
    height: 316px;
    padding: 80px;
  }

  @media (max-width: 768px) {
    padding: 40px;
  }

  @media (max-width: 594px) {
    height: 288px;
    padding: 16px;
  }
`;

const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
  justify-content: center;

  @media (max-width: 768px) {
    gap: 32px;
  }
  @media (max-width: 594px) {
    gap: 16px;
  }
`;

const CommonTextCss = css`
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: 72px;
  letter-spacing: -1.2px;

  @media (max-width: 1200px) {
    font-size: 48px;
    line-height: 64px;
    letter-spacing: -0.96px;
  }

  @media (max-width: 594px) {
    font-size: 32px;
    line-height: 48px;
    letter-spacing: -0.64px;
  }
`;

const Title = styled.div`
  ${CommonTextCss}
  &>.colored-text {
    color: #eeff41;
  }
  & > .normal-text {
    color: #fff;
  }
`;

const Description = styled.div`
  color: #fff;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 20px;
  }
  @media (max-width: 594px) {
    font-size: 14px;
    line-height: 20px;
  }
`;

const AboutContainer = styled.div`
  padding: 0 120px;

  @media (max-width: 968px) {
    padding: 0 80px;
  }
  @media (max-width: 768px) {
    padding: 0 40px;
  }
  @media (max-width: 594px) {
    padding: 0 16px;
  }
`;

const AboutFounders = styled.div`
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 108px;
`;

const TitleWrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  @media (max-width: 768px) {
    gap: 16px;
  }
  @media (max-width: 594px) {
    gap: 8px;
  }
`;

const SubTitle = styled.div`
  color: #fff;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  @media (max-width: 768px) {
    font-size: 16px;
  }
  @media (max-width: 594px) {
    font-size: 14px;
  }
`;
const FoundersContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  @media (max-width: 1200px) {
    gap: 112px;
  }
`;

const FounderCard = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
  gap: 44px;
  border: 2px solid rgba(255, 255, 255, 0.2);
  background: linear-gradient(0deg, #000 0%, #000 100%),
    linear-gradient(180deg, rgba(39, 37, 37, 0) 0%, rgba(1, 1, 1, 0) 100%);
  backdrop-filter: blur(20px);
  border-radius: 24px;

  @media (max-width: 1200px) {
    width: 100%;
  }
  @media (max-width: 594px) {
    width: 100%;
    justify-content: center;
  }
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 180px;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 24px;

  @media (max-width: 1200px) {
    flex-direction: row;
    ${({ position }) => {
      if (position % 2 === 1)
        return css`
          flex-direction: row-reverse;
          justify-content: space-around;
        `;
    }}
  }
  @media (max-width: 594px) {
    flex-direction: column;
    width: 100%;
    justify-content: center;
  }
`;

const About = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
`;

const Name = styled.div`
  color: #fff;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
`;

const Experience = styled.div`
  color: rgba(255, 255, 255, 0.6);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

const LinkedInWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 8px;
  margin-top: 32px;
  justify-content: end;

  @media screen and (min-width: 594px) and (max-width: 1200px) {
    margin-top: 0;
    justify-content: start;
  }
`;

const LinkedIn = styled.div`
  color: #eeff41;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  cursor: pointer;
`;

const ImageContainer = styled.div`
  z-index: 10;
  width: 300px;
  height: 236px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: relative;
  margin: -88px auto 0 auto;

  @media (max-width: 1200px) {
    padding: 0 25px;
  }

  @media (max-width: 594px) {
    width: 270px;
    padding: 0;
  }
`;

const Image = styled.img`
  width: 272px;
  height: 214px;
  border-radius: 40px;

  @media (max-width: 330px) {
    width: 242px;
  }
`;

const HiringContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const HiringText = styled.div`
  font-size: 30px;
  text-align: center;
  font-style: normal;
  font-weight: 700;
  line-height: 38px;

  @media (max-width: 594px) {
    font-size: 24px;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.48px;
  }
`;

const HiringDescription = styled.div`
  color: #fff;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  max-width: 500px;
  @media (max-width: 768px) {
    font-size: 16px;
  }
  @media (max-width: 594px) {
    font-size: 14px;
    max-width: 100%;
    padding: 0 16px;
  }
`;

const HiringDescriptionSpan = styled.a`
  text-decoration: none;
  color: #4acddf;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  :hover {
    cursor: pointer;
  }
`;

const FOUNDERS = [
  {
    name: "saumeet nanda",
    experience:
      "Ex-BCG, 10+ years of experience in risk modelling and lending business strategy",
    image: saumeet,
    linkedIn: "https://www.linkedin.com/in/saumeet-nanda-93104ba2/",
  },
  {
    name: "mohit gorisariya",
    experience:
      "Ex-BCG, 10+ years of experience in building credit card and lending businesses",
    image: mohit,
    linkedIn: " https://www.linkedin.com/in/mohit-gorisariya-77b48215/",
  },
  {
    name: "piyush bagaria",
    experience:
      "Ex-CVC capital, 10+ years of experience at leading IB & PE/VC firms",
    image: piyush,
    linkedIn: " https://www.linkedin.com/in/piyushbagaria/",
  },
];

const AboutUs = (props) => {
  const { showModal, setShowModal } = props;
  const [isScrolled, setscrolled] = useState(false);
  const location = useLocation();
  const { pathname } = location || {};

  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => setscrolled(true), 100); // Set timeout duration as needed
  }, [pathname, setscrolled]);

  if (!isScrolled) {
    return (
      <div style={{ background: "black", width: "100%", height: "100dvh" }} />
    );
  }

  return (
    <Container>
      <Banner>
        <ContentWrapper>
          <Title>
            <span className="normal-text">about Salary</span>
            <span className="colored-text">Se</span>
          </Title>
          <Description>
            at SalarySe, we are building a full stack financial platform that
            will help more that 100 million salaried employees in India spend,
            save, invest and plan their salaries smartly.
          </Description>
        </ContentWrapper>
      </Banner>
      <AboutContainer>
        <AboutFounders>
          <TitleWrapper>
            <Title>meet the founders</Title>
            <SubTitle>masterminds behind our journey</SubTitle>
          </TitleWrapper>
          <FoundersContainer>
            {FOUNDERS.map((founders, index) => {
              const { name, experience, image, linkedIn } = founders || {};

              return (
                <FounderCard key={index}>
                  <Card position={index}>
                    <ImageContainer>
                      {index % 2 !== 1 && <DoubleVectorIcon />}
                      {index % 2 === 1 && <SingleVectorIcon />}
                      <Image src={image} alt="founder" />
                    </ImageContainer>
                    <About>
                      <Name>{name}</Name>
                      <Experience>{experience}</Experience>
                      <LinkedInWrapper
                        onClick={() => window.open(linkedIn, "_blank")}
                      >
                        <LinkedIn>linkedin</LinkedIn>
                        <ArrowCircleRight />
                      </LinkedInWrapper>
                    </About>
                  </Card>
                </FounderCard>
              );
            })}
          </FoundersContainer>
        </AboutFounders>
        <Separator height="120px" tabheight="100px" mobileheight="48px" />
        <Champions aboutUsPage setShowModal={setShowModal} />
      </AboutContainer>
      <Separator height="100px" mobileheight="48px" />
      <HiringContainer>
        <HiringText>we are hiring!</HiringText>
        <Separator height="24px" mobileheight="16px" />
        <HiringDescription>
          explore infinite opportunities across product, design and tech.{" "}
          <HiringDescriptionSpan
            href="https://forms.gle/8SoBJYtmWCVzpPWY6"
            target="_blank"
          >
            reach out to us&nbsp;
            <CheckSquare />
          </HiringDescriptionSpan>
        </HiringDescription>
      </HiringContainer>
      <Separator height="100px" tabheight="84px" mobileheight="40px" />
      <Footer setShowModal={setShowModal} />
      <ContactUsModal showModal={showModal} setShowModal={setShowModal} />
    </Container>
  );
};

export default AboutUs;
