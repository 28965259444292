import { FlexView, Separator } from "screens/common-styled-component";
import styled from "styled-components";

const ContainerWrapper = styled(FlexView)`
  padding: 60px 0;

  @media (max-width: 768px) {
    padding: 52px 0;
  }

  @media (max-width: 480px) {
    padding: 48px 0;
  }
`;

const TitleContainer = styled(FlexView)`
  gap: 18px;

  @media (max-width: 768px) {
    gap: 16px;
  }

  @media (max-width: 375px) {
    gap: 8px;
  }
`;

const ServicesInfoTitle = styled.div`
  font-size: 40px;
  max-width: 680px;
  font-weight: 900;
  text-align: center;
  transition: font 0.2s ease-out;

  @media (max-width: 768px) {
    font-size: 38px;
  }

  @media (max-width: 594px) {
    font-size: 24px;
    padding: 0 16px;
  }
`;

const GrowthText = styled.div`
  font-size: 16px;
  font-weight: 400;

  @media (max-width: 594px) {
    font-size: 14px;
  }
`;

const CardsContainer = styled(FlexView)`
  max-width: 100%;
`;

const ServicesHeader = styled(FlexView)`
  padding: 16px 40px;
  border-radius: 28px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: #191919;
  margin: 48px 0;

  @media (max-width: 594px) {
    padding: 13px 22px;
    gap: 20px;
    max-width: 100%;
  }

  @media (max-width: 780px) {
    margin: 40px 16px;
  }
`;

const SliderWrapper = styled.div`
  width: 100%;
`;

const ServiceItemText = styled.div(({ $selected }) => {
  return `
    color: ${$selected ? "#fff" : "rgba(255, 255, 255, 0.50)"};
    font-weight: ${$selected ? "900" : "400"};
    font-size: 16px;
    text-align: center;
    cursor: pointer;

    @media (max-width: 594px) {
      font-size: 12px;
    }
  `;
});

const ServiceInfoCardContainer = styled(FlexView)`
  position: relative;
  overflow: hidden;
  border-radius: 24px;
  border: 1px solid rgba(255, 255, 255, 0.4);
  background: rgba(0, 0, 0);
  backdrop-filter: blur(160px);
  transition: all 0.2s ease-out;
  min-width: 218px;
  max-width: 860px;

  padding: 40px 38px 0px;

  @media (max-width: 1024px) {
    height: 440px;
    margin: 0 30px;
  }

  @media (max-width: 768px) {
    width: 100%;
    height: 332px;
    border-radius: 20px;
    padding: 40px 16px 3%;
    margin: 0 16px;
    backdrop-filter: blur(128px);
  }

  @media (max-width: 594px) {
    min-height: 510px;
    height: 100%;
    padding: 40px 16px 0;
    border-radius: 20px;
    backdrop-filter: blur(128px);
  }

  @media (min-width: 1025px) {
    height: calc(480px - 40px);
  }
`;

const SpotLight = styled.div`
  position: absolute;
  width: 400px;
  height: 400px;
  background: ${({ $color }) => `linear-gradient(
    180deg,
    ${$color + "66"} 0%,
    ${$color + "00"} 100%
  )`};
  filter: blur(120px);
  z-index: -1;
  top: -10%;
  right: 5%;

  @media (max-width: 594px) {
    width: 350px;
    height: 350px;

    top: -10%;
    right: -10%;
  }
`;

const InfoCardTitle = styled(FlexView)`
  color: ${({ $color }) => $color};
  font-weight: 900;
  transition: font 0.2s ease-out;
  text-align: center;
  font-size: 40px;

  @media (max-width: 768px) {
    font-size: 32px;
  }

  @media (max-width: 594px) {
    font-size: 24px;
  }
`;

const StyledSeparator = styled(Separator)`
  width: 80px;
  margin: 40px auto;
  background-color: rgba(255, 255, 255, 0.2);

  @media (max-width: 768px) {
    width: 60px;
    margin-top: 27px;
  }

  @media (max-width: 594px) {
    margin: 28px auto;
  }
`;

const InfoCardTitleSubText = styled.div`
  width: 100%;
  font-size: 16px;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 14px;
  }

  @media (max-width: 375px) {
    font-size: 12px;
    font-weight: 400;
  }
`;

const InfoCardItemWrapper = styled(FlexView)`
  @media (max-width: 594px) {
    width: 100%;
    flex-direction: column;
  }
`;

const InfoCardItem = styled(FlexView)`
  width: 240px;

  @media (max-width: 1024px) {
    width: auto;
  }

  @media (max-width: 768px) {
    width: auto;
  }

  @media (max-width: 594px) {
    flex-direction: row;
    width: 100%;
    gap: 16px;
  }
`;

const InfoCardItemText = styled(FlexView)`
  gap: 8px;

  @media (max-width: 594px) {
    width: 176px;
  }
`;

const InfoCardItemTitle = styled.div`
  font-size: 20px;
  font-weight: 900;
  text-align: center;
  width: 100%;

  @media (max-width: 768px) {
    font-size: 16px;
  }

  @media (max-width: 594px) {
    text-align: start;
  }
`;

const InfoCardItemSubText = styled.div`
  color: rgba(255, 255, 255, 0.8);
  width: 100%;
  text-align: center;

  @media (max-width: 1024px) {
    font-size: 12px;
  }

  @media (max-width: 594px) {
    text-align: start;
  }

  @media (min-width: 1025px) {
    font-size: 14px;
  }
`;

const IntroCardIconWrapper = styled.div`
  @media (max-width: 768px) {
    svg {
      width: 80px;
      height: 80px;
    }
  }
`;

export {
  ContainerWrapper,
  TitleContainer,
  ServicesInfoTitle,
  GrowthText,
  CardsContainer,
  ServicesHeader,
  ServiceItemText,
  SpotLight,
  ServiceInfoCardContainer,
  InfoCardTitle,
  InfoCardTitleSubText,
  StyledSeparator,
  SliderWrapper,

  //* Info card CSS
  InfoCardItemWrapper,
  InfoCardItem,
  InfoCardItemText,
  InfoCardItemTitle,
  InfoCardItemSubText,
  IntroCardIconWrapper,
};
