import styled from "styled-components";

import { ReactComponent as SalarySe } from "../imgs/salary-se.svg";

export const FooterContainer = styled.div`
  font-family: Noto Sans;
  font-style: normal;
  padding: 120px 120px 0px 120px;
  padding-top: 80px;
  background-color: #000;
  border-top: 1px solid rgba(255, 255, 255, 0.2);

  @media (max-width: 968px) {
    padding: 0px 80px;
    padding-top: 120px;
  }
  @media (max-width: 768px) {
    padding: 0px 40px;
    padding-top: 30px;
  }
  @media (max-width: 594px) {
    padding: 0px 16px;
    padding-top: 30px;
  }
  @media (max-width: 425px) {
    padding: 24px 16px 0 16px;
    padding-top: 24px;
  }
`;

export const Section = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 12px;
`;

export const LogoGstContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 24px;
  @media (max-width: 768px) {
    gap: 18px;
  }
  @media (max-width: 425px) {
    gap: 8px;
  }
`;

export const SalarySeLogo = styled(SalarySe)`
  width: 204px;
  height: 72px;
  @media (max-width: 768px) {
    width: 129px;
    height: 30px;
  }
  @media (max-width: 425px) {
    width: 97px;
    height: 23px;
  }
`;

export const GstText = styled.div`
  color: rgba(255, 255, 255, 0.5);
  font-family: Noto Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;

  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 20px;
  }
`;

export const CompanyResourceContainer = styled.div`
  display: flex;
  color: white;
  justify-content: space-between;
  gap: 120px;
  @media (max-width: 1000px) {
    gap: 80px;
  }
  @media (max-width: 868px) {
    gap: 60px;
  }
  @media (max-width: 668px) {
    gap: 40px;
  }
  @media (max-width: 494px) {
    gap: 20px;
  }
`;

export const Company = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
  color: rgba(255, 255, 255, 0.8);
  font-size: 16px;
  line-height: 16px;
  letter-spacing: -0.32px;
  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 20px;
  }
`;

export const HeadingText = styled.div`
  font-weight: 700;
  @media (max-width: 425px) {
  }
`;

export const CompanyResourceText = styled.div`
  line-height: 24px;
  @media (max-width: 425px) {
    gap: 40px;
  }
  :hover {
    cursor: ${({ isdisabled }) => (isdisabled ? "not-allowed" : "pointer")};
    color: white;
  }
`;

export const BankingText = styled.div`
  color: rgba(255, 255, 255, 0.5);
  font-family: Noto Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  @media (max-width: 425px) {
    font-size: 14px;
    line-height: 20px;
  }
`;

export const BankingContainer = styled.div`
  padding: 40px 16px 0 16px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
`;

export const ImageContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
  justify-content: baseline;
`;

export const Image = styled.img`
  filter: grayscale(0);
`;

export const AddressContactContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
  justify-content: space-between;
  padding: 40px 16px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);

  @media (max-width: 768px) {
    padding: 40px 12px;
  }
  @media (max-width: 494px) {
    padding: 24px 0px;
  }
`;

export const AddressContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const ContactContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  @media (max-width: 1000px) {
    gap: 30px;
  }
`;
