import React from "react";
import styled from "styled-components";
import employerBrandImg from "../imgs/employer-benefits/employer-brand.svg";
import productivetyImg from "../imgs/employer-benefits/productivity.svg";
import profitabilityImg from "../imgs/employer-benefits/profitability.svg";
import { EmployerTitle, Separator } from "./common-styled-component";

const Container = styled.div`
  padding: 0 120px;

  @media (max-width: 968px) {
    padding: 0 80px;
  }
  @media (max-width: 768px) {
    padding: 0 40px;
  }
  @media (max-width: 594px) {
    padding: 0 16px;
  }
`;

const BenefitWrapper = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: center;
  gap: 40px;
  @media (max-width: 768px) {
    gap: 16px;
  }
  @media (max-width: 594px) {
    flex-direction: column;
    gap: 24px;
  }
`;

const TextWrapper = styled.div`
  width: 100%;
`;

const BenefitText = styled.div`
  color: #eeff41;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 900;
  line-height: 24px;
  @media (max-width: 768px) {
    font-size: 16px;
    letter-spacing: -0.32px;
  }
  @media (max-width: 594px) {
    line-height: 30px;
    text-align: left;
  }
`;

const BenefitDesc = styled.div`
  color: rgba(255, 255, 255, 0.8);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.32px;
    color: rgba(255, 255, 255, 0.7);
  }
  @media (max-width: 594px) {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.28px;
    text-align: left;
    color: rgba(255, 255, 255, 0.8);
  }
`;

const BenefitContent = styled.div`
  width: 100%;
  height: 288px;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  padding: 32px 24px;
  gap: 24px;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    padding: 16px;
    gap: 8px;
    height: 236px;
  }
  @media (max-width: 594px) {
    width: auto;
    height: auto;
    flex-direction: row;
    gap: 24px;
    padding: 19px 19px 25px;
  }
`;

const Image = styled.img`
  height: 120px;
  width: 120px;
  @media (max-width: 768px) {
    height: 80px;
    width: 80px;
  }
`;

const benefitsConfigs = [
  {
    image: employerBrandImg,
    title: "build employer brand",
    description: "become a “great place to work”",
  },
  {
    image: productivetyImg,
    title: "enhance productivity",
    description: "motivated and committed workforce",
  },
  {
    image: profitabilityImg,
    title: "improve profitability",
    description: "reduce manpower cost and attrition",
  },
];

function EmployerBenefits(props) {
  return (
    <Container>
      <EmployerTitle>
        <span className="normal-text">
          designed - <br /> for{" "}
        </span>
        <span className="colored-text">employer benefits</span>
      </EmployerTitle>
      <Separator height="64px" tabheight="40px" mobileheight="32px" />
      <BenefitWrapper>
        {benefitsConfigs.map((elementConfig, index) => (
          <BenefitContent key={index}>
            <Image height={120} width={120} src={elementConfig.image} />
            <TextWrapper>
              <BenefitText>{elementConfig.title}</BenefitText>
              <Separator height="16px" tabheight="8px" />
              <BenefitDesc>{elementConfig.description}</BenefitDesc>
            </TextWrapper>
          </BenefitContent>
        ))}
      </BenefitWrapper>
    </Container>
  );
}

export default EmployerBenefits;
