import React, { PureComponent } from "react";
import PropTypes from "prop-types";

class DropdownContainer extends PureComponent {
  constructor(props) {
    super(props);

    this.listRef = React.createRef();
    this.dropdownRef = React.createRef();

    const selectedItem = props.defaultItem ? { ...props.defaultItem } : null;

    this.state = {
      selectedItem,
      showOptionList: false,
    };
  }

  componentDidMount() {
    const { selected } = this.props;

    if (selected) {
      this.setState({ selectedItem: selected });
    }
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentDidUpdate(prevProps) {
    const { selected, defaultItem } = this.props;

    /* eslint-disable */
    if (
      defaultItem &&
      prevProps.defaultItem &&
      prevProps.defaultItem.id !== defaultItem.id
    ) {
      this.setState({ selectedItem: defaultItem });
    }

    /* eslint-disable */
    if (selected) {
      this.setState({ selectedItem: selected });
    }
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = (e) => {
    if (
      this.listRef.current &&
      !this.listRef.current.contains(e.target) &&
      this.dropdownRef.current &&
      !this.dropdownRef.current.contains(e.target)
    ) {
      this.setState({
        showOptionList: false,
      });
    }
  };

  dropDown = () => {
    this.setState((prevState) => ({
      showOptionList: !prevState.showOptionList,
    }));
  };

  handleListDisplay = () => {
    this.setState((prevState) => {
      return {
        showOptionList: !prevState.showOptionList,
      };
    });
  };

  handleOptionClick = (option) => {
    const { onChange } = this.props;

    if (onChange) {
      onChange(option.id);
    }

    this.setState({
      selectedItem: option,
      showOptionList: false,
    });
  };

  render() {
    const { children } = this.props;
    const { showOptionList, selectedItem } = this.state;

    return children({
      showOptionList,
      selectedItem,
      handleListDisplay: this.handleListDisplay,
      handleOptionClick: this.handleOptionClick,
      listRef: this.listRef,
      dropdownRef: this.dropdownRef,
      dropDown: this.dropDown,
    });
  }
}

DropdownContainer.defaultProps = {
  defaultItem: null,
  selected: null,
  onChange: () => {},
  children: () => {},
};

DropdownContainer.propTypes = {
  defaultItem: PropTypes.shape({}),
  selected: PropTypes.shape({}),
  onChange: PropTypes.func,
  children: PropTypes.func,
};

export default DropdownContainer;
