import React, { useCallback, useMemo, useState } from "react";
import Slider from "react-slick";

import {
  CardsContainer,
  ContainerWrapper,
  ServiceItemText,
  ServicesHeader,
  SliderWrapper,
} from "./services-info-styled";
import ServiceInfoTitle from "./title";
import ServiceInfoCards from "./cards";
import { serviceCardData, serviceItems } from "./constants";
import { ReactComponent as GrowthSVG } from "imgs/line-icons/growth.svg";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./slick-carousel.css";

const ServicesProvidedScreen = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [sliderRef, setSliderRef] = useState(null);

  const isTabletNMobileScreen = window.innerWidth <= 768;

  const handleSlickBeforeChange = useCallback(
    (_, nextSlide) => {
      if (isTabletNMobileScreen) {
        const ele = document.getElementsByClassName("slick-dots");

        if (nextSlide > 2) {
          ele[0].children[3].setAttribute("class", "slick-active");
        } else {
          ele[0].children[3].removeAttribute("class");
        }
      }

      setCurrentSlide(Math.ceil(nextSlide) ?? 0);
    },
    [isTabletNMobileScreen]
  );

  const sliderSettings = useMemo(
    () => ({
      dots: true,
      speed: 500,
      slidesToShow: isTabletNMobileScreen ? 1.1 : 1,
      slidesToScroll: 1,
      infinite: false,
      autoplay: false,
      arrows: false,
      beforeChange: handleSlickBeforeChange,
    }),
    [handleSlickBeforeChange, isTabletNMobileScreen]
  );

  return (
    <ContainerWrapper $flexDirection="column" $alignItems="center">
      <ServiceInfoTitle
        title="level up your workforce with SalarySe 360° solutions"
        subText="financially fit employees = thriving organisation"
        subTextIcon={<GrowthSVG />}
      />

      <CardsContainer $flexDirection="column" $alignItems="center">
        <ServicesHeader $alignItems="center" $flexGap="40px">
          {serviceItems.map((services, idx) => {
            const { label, id } = services;
            const slideSelected = idx === currentSlide;

            return (
              <ServiceItemText
                onClick={() => sliderRef?.slickGoTo(idx)}
                $selected={slideSelected}
                key={id}
              >
                {label}
              </ServiceItemText>
            );
          })}
        </ServicesHeader>

        <SliderWrapper className="service-card-slider">
          <Slider {...sliderSettings} ref={(slider) => setSliderRef(slider)}>
            {serviceCardData.map((cardData) => (
              <ServiceInfoCards key={cardData.id} cardData={cardData} />
            ))}
          </Slider>
        </SliderWrapper>
      </CardsContainer>
    </ContainerWrapper>
  );
};

export default ServicesProvidedScreen;
