import { FlexView } from "screens/common-styled-component";
import {
  GrowthText,
  ServicesInfoTitle,
  TitleContainer,
} from "./services-info-styled";

const ServiceInfoTitle = ({ title, subText, subTextIcon }) => {
  return (
    <TitleContainer $flexDirection="column" $alignItems="center">
      <ServicesInfoTitle>{title}</ServicesInfoTitle>

      <FlexView $alignItems="center" $flexGap="4px">
        {subTextIcon}
        <GrowthText>{subText}</GrowthText>
      </FlexView>
    </TitleContainer>
  );
};

export default ServiceInfoTitle;
