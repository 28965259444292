import {
  InfoCardItem,
  InfoCardItemSubText,
  InfoCardItemText,
  InfoCardItemTitle,
  InfoCardItemWrapper,
  InfoCardTitle,
  InfoCardTitleSubText,
  IntroCardIconWrapper,
  ServiceInfoCardContainer,
  SpotLight,
  StyledSeparator,
} from "./services-info-styled";

import { FlexView } from "screens/common-styled-component";

const ServiceInfoCards = ({ cardData }) => {
  const {
    title,
    color,
    title_sub_text: titleSubText,
    card_items: cardItems,
  } = cardData;

  return (
    <ServiceInfoCardContainer $flexDirection="column" $alignItems="center">
      <SpotLight $color={color} />
      <FlexView $flexDirection="column" $alignItems="center" $flexGap="16px">
        <InfoCardTitle $color={color}>{title}</InfoCardTitle>
        <InfoCardTitleSubText>{titleSubText}</InfoCardTitleSubText>
      </FlexView>

      <StyledSeparator height="1px" />

      <InfoCardItemWrapper $flexGap="32px">
        {cardItems.map((card) => {
          const { label, card_info: cardInfo, icon } = card;

          return (
            <InfoCardItem
              $flexDirection="column"
              $flexGap="16px"
              $alignItems="center"
            >
              <IntroCardIconWrapper>{icon}</IntroCardIconWrapper>

              <InfoCardItemText $flexDirection="column">
                <InfoCardItemTitle>{label}</InfoCardItemTitle>
                <InfoCardItemSubText>{cardInfo}</InfoCardItemSubText>
              </InfoCardItemText>
            </InfoCardItem>
          );
        })}
      </InfoCardItemWrapper>
    </ServiceInfoCardContainer>
  );
};

export default ServiceInfoCards;
