import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styled, { css } from "styled-components";

import { ReactComponent as Hamburger } from "../imgs/hamburger.svg";
import { ReactComponent as SalarySe } from "../imgs/salary-se.svg";
import MobileMenu from "./mobile-menu";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: black;
  padding: 16px 80px;
  position: sticky;
  top: 0;
  z-index: 50;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  @media (max-width: 768px) {
    padding: 8px 32px;
  }
  @media (max-width: 594px) {
    padding: 8px 8px 8px 16px;
  }
`;

const MenuWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;

  @media (max-width: 594px) {
    flex: 1;
    justify-content: flex-end;
  }
  @media (max-width: 330px) {
    flex: 2;
  }
`;

const MenuPill = styled(Link)`
  cursor: pointer;
  text-decoration: none;
  padding: 16px;
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  position: relative;
  ${({ border }) =>
    border &&
    css`
      border: 1px solid #fff;
    `}
  ${({ active }) =>
    active &&
    css`
      font-weight: 700;
      color: #eeff41;
      path {
        stroke: #eeff41;
      }
      &:after {
        position: absolute;
        content: "";
        left: 0;
        bottom: -16px;
        height: 2px;
        width: 100%;
        background: #eeff41;
      }
    `}

  @media (max-width: 768px) {
    padding: 12px 16px;
    ${({ active }) =>
      active &&
      css`
        &:after {
          bottom: -8px;
        }
      `}
  }
  @media (max-width: 594px) {
    padding: 8px 8px 8px 16px;
    ${({ mobileDisplay }) =>
      mobileDisplay &&
      css`
        display: ${mobileDisplay};
      `}

    font-size: 14px;
    line-height: 24px;
  }

  ${({ contact }) =>
    contact &&
    css`
      :hover {
        transition: all 200ms ease-in-out;
        cursor: pointer;
        background: white;
        color: black;
      }
      border-radius: 8px;
    `}
`;

const SalarySeLogo = styled(SalarySe)`
  cursor: pointer;
  width: 101px;
  height: 24px;

  @media (max-width: 594px) {
    margin-left: 24px;
  }
`;

const HamburgerIconWrapper = styled.div`
  display: none;

  @media (max-width: 594px) {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
`;

const Header = (props) => {
  const { setShowModal, showModal } = props;
  const [showMenu, setShowMenu] = useState(false);
  const location = useLocation();
  const { pathname } = location || {};

  const navigate = useNavigate();

  const handleMenuToggle = () => {
    setShowMenu((prev) => {
      if (prev) {
        document.body.style.overflow = "initial";
      } else {
        document.body.style.overflow = "hidden";
      }

      return !prev;
    });
  };

  console.log(pathname);

  return (
    <Container>
      <HamburgerIconWrapper>
        <Hamburger onClick={handleMenuToggle} />
      </HamburgerIconWrapper>
      <SalarySeLogo onClick={() => navigate("/")} />
      <MenuWrapper>
        <MenuPill to="/" mobileDisplay="none" active={pathname === "/"}>
          home
        </MenuPill>
        <MenuPill
          to="/for-employer"
          mobileDisplay="none"
          active={pathname.startsWith("/for-employer")}
        >
          for employer
        </MenuPill>
        <MenuPill
          to="/about-us"
          mobileDisplay="none"
          active={pathname.startsWith("/about-us")}
        >
          about us
        </MenuPill>
        <MenuPill contact onClick={() => setShowModal(!showModal)} border>
          contact us
        </MenuPill>
      </MenuWrapper>
      <MobileMenu
        setShowModal={setShowModal}
        showMenu={showMenu}
        handleMenuToggle={handleMenuToggle}
      />
    </Container>
  );
};

export default Header;
