import { useCallback, useEffect, useRef, useState } from "react";
import { Parallax } from "react-scroll-parallax";
import styled from "styled-components";
import IntroCardView from "./view";
import {
  CreditCardPics,
  InvestCardPics,
  SaveCardPics,
  UPICardPics,
} from "./card-based-pics";
import { ParallaxProvider } from "react-scroll-parallax";
import { getScreenWidth, getScrollY } from "utils/index";

const Section = styled.section`
  width: 100%;

  @media (max-width: 580px) {
    height: 2660px;
  }

  @media (min-width: 581px) and (max-width: 1022px) {
    height: 2860px;
  }

  @media (min-width: 1024px) {
    height: 2460px;
  }
`;

const Wrapper = styled(Parallax)`
  position: sticky;
  overflow: hidden;
  top: 150px;
  transition: all 0.2s ease-out;
  border-radius: 24px;

  @media (max-width: 1023px) {
    top: 40px;
    background-image: linear-gradient(136deg, #202020 0%, #0b0b0b 100%),
      linear-gradient(136deg, #eeff4130 0%, #3d41fa30 60.05%);
    background-clip: padding-box, border-box;
    background-origin: border-box;
    border: 2px solid transparent;
    border-radius: 32px;
    padding-right: 4px;
  }

  @media (min-width: 1024px) {
    height: 644px;
    transform: translateX(40px);
    border-radius: 0;
  }
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  margin: auto;
  position: relative;

  @media (max-width: 580px) {
    height: 700px;
  }

  @media (min-width: 581px) and (max-width: 1023px) {
    height: 684px;
  }

  @media (min-width: 1024px) {
    height: 640px;
  }
`;

const SectionWrapper = styled.div`
  position: sticky;
  top: 100px;
  width: 100%;
  overflow: hidden;

  ::-webkit-scrollbar {
    display: none;
  }

  @media (min-width: 1024px) {
    width: calc(100% + 40px);
  }

  @media (max-width: 580px) {
    top: 70px;
  }
`;

const IntroCardModule = () => {
  const [eleEntered, setEleEntered] = useState();
  const [parallaxState1, setParallaxState1] = useState({});
  const [parallaxState2, setParallaxState2] = useState({});
  const [parallaxState3, setParallaxState3] = useState({});
  const [showUPI, setShowUPI] = useState(false);
  const [showInvest, setShowInvest] = useState(true);
  const [showCredit, setShowCredit] = useState(true);
  const [showSave, setShowSave] = useState(true);

  const elementVPTop = useRef(0);

  const handleProgress = useCallback(() => {
    const screenWidth = getScreenWidth();
    const topOffset = getScrollY();

    if (screenWidth < 581) {
      if (topOffset < elementVPTop.current) {
        setShowInvest(true);
      } else if (
        topOffset >= elementVPTop.current &&
        topOffset <= elementVPTop.current + 800
      ) {
        setShowUPI(false);
        setShowInvest(false);
        setShowCredit(true);
      } else if (
        topOffset >= elementVPTop.current + 801 &&
        topOffset <= elementVPTop.current + 1200
      ) {
        setShowUPI(true);
        setShowCredit(false);
        setShowSave(true);
        setShowInvest(false);
      } else if (
        topOffset >= elementVPTop.current + 1201 &&
        topOffset <= elementVPTop.current + 1600
      ) {
        setShowSave(false);
        setShowCredit(false);
        setShowInvest(true);
      } else if (topOffset >= elementVPTop.current + 1601) {
        setShowCredit(true);
      }
    } else {
      if (topOffset >= 1200 && topOffset <= 1400) {
        setShowInvest(true);
      } else if (topOffset >= 1401 && topOffset <= 1800) {
        setShowUPI(false);
        setShowInvest(false);
        setShowCredit(true);
      } else if (topOffset >= 1801 && topOffset <= 2400) {
        setShowUPI(true);
        setShowCredit(false);
        setShowInvest(false);
        setShowSave(true);
      } else if (topOffset >= 2401 && topOffset <= 3000) {
        setShowInvest(true);
        setShowCredit(false);
        setShowSave(false);
      } else if (topOffset >= 3001 && topOffset <= 3600) {
        setShowCredit(true);
      }
    }
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleProgress);
    window.addEventListener("resize", handleProgress);

    const sectionElement = document.getElementById("intro-card-section");

    elementVPTop.current = sectionElement.offsetTop;

    return () => {
      window.removeEventListener("scroll", handleProgress);
      window.removeEventListener("resize", handleProgress);
    };
  }, [handleProgress]);

  useEffect(() => {
    if (eleEntered) {
      setParallaxState1({
        translateX: window.innerWidth >= 1024 && [78, 8],
        translateY: window.innerWidth < 1024 && [70, 8],
        startScroll: window.innerWidth < 581 ? elementVPTop.current : 1400,
        endScroll: window.innerWidth < 581 ? elementVPTop.current + 800 : 1800,
      });
      setParallaxState2({
        translateX: window.innerWidth >= 1024 && [85, 16],
        translateY: window.innerWidth < 1024 && [80, 17],
        startScroll:
          window.innerWidth < 581 ? elementVPTop.current + 850 : 1810,
        endScroll: window.innerWidth < 581 ? elementVPTop.current + 1250 : 2400,
      });
      setParallaxState3({
        translateX: window.innerWidth >= 1024 && [91, 24],
        translateY: window.innerWidth < 1024 && [90, 26],
        startScroll:
          window.innerWidth < 581 ? elementVPTop.current + 1300 : 2410,
        endScroll: window.innerWidth < 581 ? elementVPTop.current + 1700 : 3000,
      });
    }
  }, [eleEntered]);

  return (
    <ParallaxProvider>
      <Section id="intro-card-section">
        <SectionWrapper>
          <Wrapper
            onEnter={() => {
              setEleEntered(true);
            }}
          >
            <Container>
              <IntroCardView
                backgroundColor="gray"
                componentData={{
                  title: "UPI",
                  titleSuffix: "SalarySe",
                  content:
                    "convenience of UPI, flexibility of credit card. The most rewarding credit-on-UPI app",
                  bulletPoints: [
                    <div>
                      <strong>upto 2% returns</strong> on all transactions
                    </div>,
                    <div>
                      <strong>upto 10% extra savings</strong> on partnered
                      brands
                    </div>,
                  ],
                }}
                cardData={{
                  showCard: showUPI,
                  cardName: "UPI",
                }}
                imageContainerFiller={<UPICardPics />}
              />
              <IntroCardView
                backgroundColor="yellow"
                parallaxState={parallaxState1}
                componentData={{
                  title: "invest",
                  titleSuffix: "SalarySe",
                  content: `leapfrog to the next phase of your life. Wealth generation without any risks`,
                  bulletPoints: [
                    <strong>upto 10% assured returns</strong>,
                    <strong>secured by RBI</strong>,
                  ],
                }}
                cardData={{ showCard: showInvest, cardName: "Invest" }}
                imageContainerFiller={<InvestCardPics />}
              />
              <IntroCardView
                backgroundColor="blue"
                parallaxState={parallaxState2}
                componentData={{
                  title: "credit",
                  titleSuffix: "SalarySe",
                  content: `never wait to get what you want. Money always at your fingertips`,
                  bulletPoints: [
                    <div>
                      get <strong>cash in bank within 5 min</strong>
                    </div>,
                    <div>
                      <strong>save 20%</strong> on interest cost
                    </div>,
                    <div>
                      repay with <strong>easy EMIs</strong>
                    </div>,
                  ],
                }}
                cardData={{ showCard: showCredit, cardName: "Credit" }}
                imageContainerFiller={<CreditCardPics />}
              />
              <IntroCardView
                backgroundColor="green"
                parallaxState={parallaxState3}
                componentData={{
                  title: "save",
                  titleSuffix: "SalarySe",
                  content: `never miss a chance to save daily on your favourite brands.`,
                  bulletPoints: [
                    <div>
                      <strong>save 25%</strong> across 300+ brands
                    </div>,
                  ],
                }}
                cardData={{ showCard: showSave, cardName: "Saved" }}
                imageContainerFiller={<SaveCardPics />}
              />
            </Container>
          </Wrapper>
        </SectionWrapper>
      </Section>
    </ParallaxProvider>
  );
};

export default IntroCardModule;
