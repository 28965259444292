import React, { useState } from "react";
import background from "../../imgs/backgroundline.svg";

import "./submit-form.css";
import axios from "axios";
import { EmployerTitle } from "screens/common-styled-component";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const formDefaultData = {
  name: "",
  email: "",
  phone: "",
  employer: "",
  message: "",
  designation: "",
  whatsappFlag: true,
};

function SubmitForm() {
  const [showError, setShowError] = useState(false);
  const [formDemoReply, setFormDemoReply] = useState("");
  const [data, setData] = useState(formDefaultData);
  const [emailInvalid, setEmailInvalid] = useState(false);

  const FORM_HEADER = {
    headers: {
      Accept: "*/*",
      "Content-Type": "application/json",
    },
  };

  async function fetchAssetsAPi() {
    const API_URL = "https://api.salaryse.com/gw/v1/save/lead";

    try {
      const response = await axios.post(API_URL, data, FORM_HEADER);

      return response;
    } catch (err) {}
  }

  const submitForm = () => {
    const { name, designation, employer, email } = data;
    data.demoFlag = formDemoReply === "yes" ? true : false;

    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (email && !email.match(emailPattern)) {
      setEmailInvalid(true);
      setShowError(true);
    } else {
      if (name && employer && designation) {
        data.demoFlag = formDemoReply === "yes" ? true : false;
        data.roleType = "EMPLOYER";
        data.companyEmail = data.email;
        console.log(data);
        fetchAssetsAPi();
        setData(formDefaultData);
        setShowError(false);
        toast.success("Thank you for showing interest in SalarySe");
      } else {
        setShowError(true);
      }
    }
  };

  return (
    <>
      <ToastContainer />

      <div className="form-container-wrapper">
        <EmployerTitle>ready to get started?</EmployerTitle>
        <div className="form-img">
          <img className="img-body" src={background} alt="" />{" "}
          <div className="form-img-dec">
            we ensure seamless and 100% secured experience
          </div>
        </div>
        <div className="form-container">
          <div className="form-wrapper">
            <div className="form-modal-content-right">
              <div className="form-input-container">
                <div
                  className={`form-width-85 ${
                    showError && !data.name && "form-show-error"
                  }`}
                >
                  <div className="form-flex-space-between ">
                    <div className="form-title">
                      name <span className="form-red">*</span>
                    </div>
                    {showError && !data.name && (
                      <div className="form-red">(required)</div>
                    )}
                  </div>
                  <div className={`form-input-box `}>
                    <input
                      type="text"
                      value={data.name}
                      onChange={(event) => {
                        setData({ ...data, name: event.target.value });
                      }}
                    />
                  </div>
                </div>
                <div
                  className={`form-width-85 ${
                    showError &&
                    (!data.email || emailInvalid) &&
                    "form-show-error"
                  }`}
                >
                  <div className="form-flex-space-between ">
                    <div className="form-title">
                      company email <span className="form-red">*</span>{" "}
                    </div>
                    {showError && !data.email && (
                      <div className="form-red">(required)</div>
                    )}
                  </div>
                  <div className={`form-input-box `}>
                    <input
                      type="email"
                      value={data.email}
                      onChange={(event) => {
                        setData({ ...data, email: event.target.value });
                      }}
                    />
                  </div>
                </div>
                <div
                  className={`form-width-85 ${
                    showError && !data.employer && "form-show-error"
                  }`}
                >
                  <div className="form-flex-space-between ">
                    <div className="form-title">
                      company name
                      <span className="form-red">*</span>
                    </div>
                    {showError && !data.employer && (
                      <div className="form-red">(required)</div>
                    )}
                  </div>
                  <div className="form-input-box">
                    <input
                      type="text"
                      value={data.employer}
                      onChange={(event) => {
                        setData({ ...data, employer: event.target.value });
                      }}
                    />
                  </div>
                </div>
                <div
                  className={`form-width-85 ${
                    showError && !data.designation && "form-show-error"
                  }`}
                >
                  <div className="form-flex-space-between ">
                    <div className="form-title">
                      designation <span className="form-red">*</span>
                    </div>
                    {showError && !data.designation && (
                      <div className="form-red">(required)</div>
                    )}
                  </div>
                  <div className="form-input-box">
                    <input
                      type="text"
                      value={data.designation}
                      onChange={(event) => {
                        setData({ ...data, designation: event.target.value });
                      }}
                    />
                  </div>
                </div>
                <div className="form-width-85">
                  <div className="form-flex-space-between ">
                    <div className="form-title">message (optional)</div>
                  </div>
                  <div className="form-input-box-area">
                    <textarea
                      value={data.message}
                      onChange={(event) => {
                        setData({ ...data, message: event.target.value });
                      }}
                    />
                  </div>
                </div>
                <div className="form-concent-input">
                  <div className="form-demo-concent">
                    do you want to book demo with us?{" "}
                  </div>
                  <div className="form-demo-reply">
                    <div
                      className={
                        formDemoReply === "yes"
                          ? "active-form-response"
                          : "form-response"
                      }
                      onClick={() => setFormDemoReply("yes")}
                    >
                      yes
                    </div>
                    <div
                      className={
                        formDemoReply === "no"
                          ? "active-form-response"
                          : "form-response"
                      }
                      onClick={() => setFormDemoReply("no")}
                    >
                      no
                    </div>
                  </div>
                </div>

                <div className="form-btn-container">
                  <div className="form-btn-container-border"></div>
                  <div className="form-check-box">
                    <input
                      checked={data.whatsappFlag}
                      onChange={() =>
                        setData({ ...data, whatsappFlag: !data.whatsappFlag })
                      }
                      type="checkbox"
                      id="vehicle1"
                      name="vehicle1"
                      value="Bike"
                    />
                    <div className="form-check-box-label" for="vehicle1">
                      receive updates over email
                    </div>
                  </div>
                  <div
                    className="form-submit-container"
                    onClick={() => submitForm()}
                  >
                    <div className="form-submit-btn">submit</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div></div>
      </div>
    </>
  );
}

export default SubmitForm;
