import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

const Container = styled.div`
  width: 272px;
  height: 32px;
  @media (max-width: 968px) {
    width: 192px;
  }
`;

const Input = styled.input`
  appearance: none;
  -webkit-appearance: none;
  background: rgba(255, 255, 255, 0.4);
  border-radius: 4px;
  height: 4px;
  width: 100%;
  outline: none;
  cursor: pointer;
  position: relative;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    background: #fff;
    border: 0;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    transition: 0.4s all ease-out;
    position: relative;
    z-index: 2;
  }
  &::before {
    content: "";
    height: 4px;
    width: var(--seek-before-width);
    background-color: #eeff41;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    cursor: pointer;
    overflow: hidden;
  }
  &::-moz-range-progress {
    background-color: #eeff41;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    height: 2px;
    overflow: hidden;
  }
`;

const InputRange = ({ defaultValue, onChange, maxValue }) => {
  
  const inputRef = useRef();
  const [range] = [(Number(defaultValue)/Number(maxValue))*100];
  const setRange = useState(range)[1];
  const handleChange = (event) => {
    inputRef.current.style.setProperty(
      "--seek-before-width",
      `${range}%`
    );
    setRange(event.target.value);
    const rangeValue = Number(event.target.value);
    const factor = 100 / rangeValue;
    const value = maxValue / factor;
    onChange(Math.round(value));
  };

  useEffect(() => {
    inputRef.current.style.setProperty("--seek-before-width", `${range}%`);
  }, [range]);

  useEffect(() => {
    if (defaultValue === 0) {
      setRange(defaultValue);
    }
  }, [defaultValue]);

  return (
    <Container>
      <Input
        type="range"
        value={range}
        min="0"
        max="100"
        ref={inputRef}
        onChange={handleChange}
      />
    </Container>
  );
};

export default InputRange;
