import { ReactComponent as StarSmallIcon } from "imgs/star.svg";
import { ReactComponent as StarBigIcon } from "imgs/star1.svg";
import React from "react";
import styled from "styled-components";
import Marquee from "react-fast-marquee";

const StarBig = styled(StarBigIcon)`
  width: 32px;
  height: 32px;

  @media (max-width: 768px) {
    width: 24px;
    height: 24px;
  }

  @media (max-width: 600px) {
    width: 18px;
    height: 18px;
  }

  @media (max-width: 425px) {
    width: 12px;
    height: 12px;
  }
`;

const StarSmall = styled(StarSmallIcon)`
  width: 32px;
  height: 32px;
  @media (max-width: 768px) {
    width: 24px;
    height: 24px;
  }
  @media (max-width: 600px) {
    width: 18px;
    height: 18px;
  }
  @media (max-width: 425px) {
    width: 12px;
    height: 12px;
  }
`;

const BannerText = styled.div`
  font-family: Noto Sans;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
  letter-spacing: -0.96px;
  background: linear-gradient(153deg, #eeff41 0%, #3d41fa 96.05%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  @media (max-width: 768px) {
    font-size: 36px;
    line-height: 45px;
    letter-spacing: -0.72px;
  }
  @media (max-width: 425px) {
    font-size: 20px;
    line-height: 33.75px;
    letter-spacing: -0.4px;
  }
`;

const Wrapper = styled.div`
  position: sticky;
  bottom: 0;
  z-index: 11;

  transition: all 0.2s ease-out;
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 48px;
  width: 100%;
  padding: 32px 0;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  overflow: hidden;
  transition: all 0.2s ease-out;

  & .rfm-marquee,
  .rfm-initial-child-container {
    width: 100%;
    justify-content: space-around;
    overflow-y: hidden;

    .rfm-child {
      flex-grow: 1;
    }
  }

  @media (max-width: 580px) {
    padding: 8px 0;
  }
`;

const Row = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

const BannerCard = ({ name, star }) => {
  return (
    <Row>
      {star ? <StarBig /> : <StarSmall />}
      <BannerText>{name}</BannerText>
    </Row>
  );
};

export const Banner = () => {
  return (
    <Wrapper>
      <Marquee speed={50}>
        <BannerCard name="UPI" star />
        <BannerCard name="invest" />
        <BannerCard name="credit" star />
        <BannerCard name="save" />
      </Marquee>
    </Wrapper>
  );
};
