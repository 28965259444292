import React from "react";
import styled, { css } from "styled-components";

import { ReactComponent as CornorStroke } from "../imgs/cornor-stroke.svg";

const MissionContainer = styled.div`
  @media (min-width: 1024px) {
  }
`;

const MissionWrapper = styled.div`
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: stretch;
  border-radius: 32px;
  border: 2px solid rgba(255, 255, 255, 0.2);
  background: linear-gradient(0deg, #000 0%, #000 100%),
    linear-gradient(180deg, rgba(39, 37, 37, 0) 0%, rgba(39, 37, 37, 0) 100%);
  padding: 72px;
  overflow: hidden;
  gap: 32px;

  @media (max-width: 1208px) {
    padding: 54px;
  }
  @media (max-width: 594px) {
    flex-direction: column;
    padding: 24px;
    gap: 16px;
  }
`;

const CornorStrokeComp = styled(CornorStroke)`
  position: absolute;
  bottom: 0;
  right: 0;

  @media (max-width: 768px) {
    width: 191px;
    height: 153px;
  }

  @media (max-width: 594px) {
    width: 143px;
    height: 115px;
  }
`;

const CommonTextCss = css`
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: 72px;
  letter-spacing: -1.2px;

  @media (max-width: 594px) {
    font-size: 32px;
    line-height: 48px;
    letter-spacing: -0.64px;
  }
`;

const Title = styled.div`
  ${CommonTextCss}
  &>.colored-text {
    color: #eeff41;
  }
  & > .normal-text {
    color: #fff;
  }
`;

const MissionTitle = styled(Title)`
  flex: 3;
  color: #fff;

  @media (max-width: 1768px) {
    flex: 2;
  }

  @media (max-width: 1368px) {
    flex: 3;
  }
  @media (max-width: 1168px) {
    flex: 5;
  }
  @media (max-width: 968px) {
    flex: 3;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 54px;
    letter-spacing: -0.72px;
  }
  @media (max-width: 594px) {
    font-size: 24px;
    line-height: normal;
    letter-spacing: -0.48px;
  }
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  flex: 4;
  @media (max-width: 960px) {
    flex: 3;
  }
  @media (max-width: 594px) {
    gap: 24px;
  }
`;
const Button = styled.button`
  z-index: 10;
  display: flex;
  padding: 16px 52px;
  justify-content: center;
  align-items: center;
  background: #3e42fa;
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  border: none;
  border-radius: 8px;
  @media (max-width: 968px) {
    padding: 12px 39px;
    font-size: 12px;
    font-weight: 700;
    line-height: 13.5px;
  }
  @media (max-width: 594px) {
    padding: 12px 39px;
  }

  :hover {
    transition: all 200ms ease-in-out;
    cursor: pointer;
    background: white;
    color: black;
  }
`;

const Description = styled.div`
  color: #fff;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  margin-bottom: 16px;
  @media (max-width: 968px) {
    font-size: 16px;
    line-height: 20px;
  }
  @media (max-width: 594px) {
    font-size: 14px;
    line-height: 20px;
  }
`;

const Champions = ({ aboutUsPage, setShowModal }) => {
  return (
    <MissionContainer aboutUsPagePadding={aboutUsPage}>
      <MissionWrapper>
        <MissionTitle>#100 million salaried champions</MissionTitle>
        <Content>
          <Description>
            our mission is to empower salaried employees and help them achieve
            financial freedom. We partner with employers to make this mission
            successful. Join us officially and become 1 in 100 million.
          </Description>
          <Button onClick={() => setShowModal(true)}>join us</Button>
        </Content>
        <CornorStrokeComp />
      </MissionWrapper>
    </MissionContainer>
  );
};

export default Champions;
