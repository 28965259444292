import React, { useCallback, useEffect, useRef, useState } from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ReactComponent as LeftArrow } from "imgs/arrow-left.svg";
import { ReactComponent as RightArrow } from "imgs/arrow-right.svg";
import "./slick-carousel.css";
import MediaCards from "./cards";
import { mediaConfig } from "./constant";
import {
  EmployerTitle,
  FlexView,
  Separator,
} from "screens/common-styled-component";
import styled from "styled-components";

const LeftArrowButton = styled(LeftArrow)`
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  position: relative;
  top: -8px;
  left: -4px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  transition: all 100ms ease-in-out;
  :hover {
    cursor: pointer;
    background: rgba(255, 255, 255, 0.4);
  }
`;

const RightArrowButton = styled(RightArrow)`
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  position: relative;
  top: -8px;
  left: -4px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  transition: all 100ms ease-in-out;
  :hover {
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.4);
  }
`;

const MediaCardsCarousal = () => {
  const [dotsContainerLength, setDotContainerWidth] = useState(null);
  const [dotsLength, setDotLength] = useState(null);
  const sliderRef = useRef(null);

  const handlePrevClick = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };
  const [currentSlide, setCurrentSlide] = useState(0);

  const handleNextClick = () => {
    if (sliderRef.current && currentSlide !== dotsLength) {
      sliderRef.current.slickNext();
    }
  };

  const handleSlickBeforeChange = useCallback(
    (_, nextSlide) => {
      const container = document.getElementById("media-card-slider");
      const d = container?.querySelectorAll(".slick-dots");

      if (nextSlide > dotsLength - 2) {
        d[0].children[dotsLength - 1]?.setAttribute("class", "slick-active");
      } else {
        d[0].children[dotsLength - 1]?.removeAttribute("class", "slick-active");
      }
      setCurrentSlide(Math.ceil(nextSlide) ?? 0);
    },
    [dotsLength]
  );

  const sliderSettings = {
    dots: true,
    speed: 1000,
    slidesToShow: 6,
    slidesToScroll: 2,
    infinite: false,
    autoplay: false,
    arrows: false,
    beforeChange: handleSlickBeforeChange,
    responsive: [
      {
        breakpoint: 1450,
        settings: {
          speed: 800,
          slidesToShow: 5.2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 1250,
        settings: {
          speed: 500,
          slidesToShow: 4.4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1050,
        settings: {
          slidesToShow: 3.6,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 850,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 760,
        settings: {
          slidesToShow: 3.5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 350,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleProgress = useCallback(() => {
    const container = document.getElementById("media-card-slider");
    const d = container?.querySelectorAll(".slick-dots");
    setDotLength(d[0].childElementCount);
    setDotContainerWidth((prev) => {
      if (prev !== d[0].offsetWidth) {
        return d[0].offsetWidth;
      }

      return prev;
    });
  }, []);

  useEffect(() => {
    if (sliderRef && sliderRef.current) {
      handleProgress();
    }
  }, [handleProgress]);

  useEffect(() => {
    const container = document.getElementById("media-card-slider");
    const d = container?.querySelectorAll(".slick-dots");

    if (d[0]?.childElementCount) {
      setDotLength((prev) =>
        prev !== d[0].childElementCount ? d[0].childElementCount : prev
      );
    }
  }, []);

  return (
    <>
      <EmployerTitle>fresh off the media</EmployerTitle>
      <Separator height="40px" tabheight="40px" mobileheight="40px" />
      <div id="media-card-slider">
        <Slider {...sliderSettings} ref={sliderRef}>
          {mediaConfig.map((media, idx) => (
            <MediaCards key={idx} media={media} />
          ))}
        </Slider>
      </div>
      {dotsContainerLength && (
        <FlexView $alignItems="center" $justifyContent="center">
          <LeftArrowButton id="left" onClick={handlePrevClick} />
          <div style={{ width: `${dotsContainerLength + 32}px` }} />
          <RightArrowButton id="right" onClick={handleNextClick} />
        </FlexView>
      )}
      <Separator height="140px" tabheight="140px" mobileheight="140px" />
    </>
  );
};

export default MediaCardsCarousal;
