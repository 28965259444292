import React from "react";
import { FlexView } from "screens/common-styled-component";
import { ReactComponent as LinkIcon } from "imgs/link-icon.svg";

import styled from "styled-components";

const HoverElement = styled(FlexView)`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 16px;
  display: none;

  background: rgba(0, 0, 0, 0.8);
`;

const Card = styled.div`
  width: 230px;
  height: 141px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  background-color: ${({ background }) => background};
  flex-shrink: 1;
  position: relative;
  @media (max-width: 768px) {
    width: 172px;
    height: 105px;
  }
  &:hover {
    border: none;
    cursor: pointer;
    ${HoverElement} {
      display: flex;
    }
  }
`;

const LinkSvg = styled(LinkIcon)`
  width: 42px;
  height: 42px;
  @media (max-width: 768px) {
    width: 32px;
    height: 32px;
  }
  @media (max-width: 425px) {
    width: 24px;
    height: 24px;
  }
`;

const MediaCards = ({ media }) => {
  const { background, img, link } = media || {};
  return (
    <Card background={background} onClick={() => window.open(link)}>
      {img}
      <HoverElement $alignItems="center" $justifyContent="center">
        <LinkSvg />
      </HoverElement>
    </Card>
  );
};

export default MediaCards;
