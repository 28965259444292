import React from "react";
import { EmployerTitle, Separator } from "./common-styled-component";
import styled from "styled-components";
import rbiImg from "../imgs/employer-safety/rbi.svg";
import complaintImg from "../imgs/employer-safety/complaint.svg";
import securityImg from "../imgs/employer-safety/security.svg";
import structureImg from "../imgs/employer-safety/structure.svg";

const Container = styled.div`
  padding: 0 120px;

  @media (max-width: 968px) {
    padding: 0 80px;
  }
  @media (max-width: 768px) {
    padding: 0 40px;
  }
  @media (max-width: 594px) {
    padding: 0 16px;
  }
`;

const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 1fr;
  gap: 44px 34px;
  @media (max-width: 968px) {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
  }
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 16px;
  padding: 24px;
  gap: 16px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  @media (max-width: 968px) {
    width: 100%;
  }

  @media (max-width: 494px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Image = styled.img`
  height: 120px;
  width: 120px;
  @media (max-width: 768px) {
    height: 80px;
    width: 80px;
  }
`;

const TextWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  @media (max-width: 768px) {
    gap: 8px;
  }
`;

const Title = styled.div`
  color: #fff;
  font-size: 24px;
  font-style: normal;
  font-weight: 900;
  line-height: 32px;
  letter-spacing: -0.48px;
  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.32px;
  }
  @media (max-width: 594px) {
    line-height: 30px;
  }
`;

const Description = styled.div`
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.32px;
  @media (max-width: 594px) {
    font-size: 14px;
    letter-spacing: -0.28px;
  }
`;

const safetyConfig = [
  {
    image: rbiImg,
    title: "RBI compliant",
    description:
      "we’re building SalarySe with the best standards to ensure absolute security to our customers.",
  },
  {
    image: complaintImg,
    title: "regulatory compliant",
    description:
      "we are India’s first and only fully regulated financial wellness provider with UPI TPAP licence",
  },
  {
    image: securityImg,
    title: "security and privacy",
    description:
      "our systems undergo rigorous data protection measures, including regular vulnerability and penetration testing to ensure 100% security",
  },
  {
    image: structureImg,
    title: "clear structure",
    description:
      "funds flow directly between employee and partner bank accounts",
  },
];

export default function EmployerSafety() {
  return (
    <Container>
      <EmployerTitle>
        <span className="normal-text">because we priortise </span>
        <br className="sentence-break" />
        <span className="colored-text">safety</span>
      </EmployerTitle>
      <Separator height="40px" tabheight="49px" mobileheight="32px" />
      <ContentWrapper>
        {safetyConfig.map((elementConfig, index) => (
          <Content key={index}>
            <Image height={120} width={120} src={elementConfig.image} />
            <TextWrapper>
              <Title>{elementConfig.title}</Title>
              <Description>{elementConfig.description}</Description>
            </TextWrapper>
          </Content>
        ))}
      </ContentWrapper>
    </Container>
  );
}
// safety icone align left in mobile view
// form subtext and imag
// pallex psisioning
