import styled from "styled-components";
import { ReactComponent as ZeroRiskSVG } from "imgs/services-info/zero-risk.svg";
import { ReactComponent as EmployeeSalaryProgressSVG } from "imgs/services-info/employee-salaray-progress.svg";
import { ReactComponent as SalaryProgressMonthSVG } from "imgs/services-info/salaray-progress-month.svg";
import { ReactComponent as UPIAccountSVG } from "imgs/services-info/uip-account.svg";
import { ReactComponent as MarkedReimbursementSVG } from "imgs/services-info/marked-reimbursement.svg";
import spendsChartPNG from "imgs/services-info/spends-chart.png";
import { FlexView } from "screens/common-styled-component";

const Wrapper = styled(FlexView)`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;

const ZeroRisk = styled(ZeroRiskSVG)`
  position: absolute;
  bottom: 44px;
  left: -17px;

  @media (max-width: 594px) {
    bottom: 16px;
  }
`;

const EmployeeSalaryProgress = styled(EmployeeSalaryProgressSVG)`
  position: absolute;
  top: 68px;
  left: -42px;
  z-index: 1;

  @media (max-width: 594px) {
    top: 28px;
  }
`;

const SalaryProgressMonth = styled(SalaryProgressMonthSVG)`
  position: absolute;
  bottom: 118px;
  left: 29px;

  @media (max-width: 594px) {
    bottom: 80px;
    left: 39px;
  }
`;

const UPIAccount = styled(UPIAccountSVG)`
  position: absolute;
  top: 22px;
  right: -16px;
  z-index: 1;

  @media (max-width: 594px) {
    top: 20px;
    right: -50px;
  }
`;

const SpendsChart = styled.img`
  position: absolute;
  width: 230px;
  top: 86px;
  left: -28px;

  @media (max-width: 768px) {
    width: 190px;
    top: 120px;
  }

  @media (max-width: 594px) {
    width: 170px;
    top: 86px;
  }
`;

const MarkedReimbursement = styled(MarkedReimbursementSVG)`
  position: absolute;
  bottom: 24px;
  right: -14px;

  @media (max-width: 594px) {
    bottom: 12px;
  }
`;

export const EmployeeAdvancesPic = () => {
  return (
    <Wrapper>
      <ZeroRisk />
      <EmployeeSalaryProgress />
      <SalaryProgressMonth />
    </Wrapper>
  );
};

export const ReimbursementsPic = () => {
  return (
    <Wrapper>
      <UPIAccount />
      <SpendsChart src={spendsChartPNG} alt="Spends" />
      <MarkedReimbursement />
    </Wrapper>
  );
};
