import React, { useEffect, useCallback, useState } from "react";
import styled from "styled-components";
import backgroundStroke from "../imgs/background-strokes-calculator.svg";
import { FlexView, InputBox, Separator } from "./common-styled-component";
import InoutRange from "./input-range";
import Dropdown from "./dropdown";

const Container = styled.div`
  background-image: url(${backgroundStroke});
  background-repeat: no-repeat;
  background-size: contain;
  padding: 0 120px;
  background-position-y: 30px;

  @media (max-width: 968px) {
    padding: 0 80px;
  }
  @media (max-width: 768px) {
    padding: 0 40px;
  }
  @media (max-width: 594px) {
    padding: 0 16px;
  }
`;

const Title = styled.div`
  color: #fff;
  text-align: center;
  font-size: 40px;
  font-style: normal;
  font-weight: 900;
  line-height: 52px;
  letter-spacing: -0.8px;
  .sentence-break {
    display: none;
  }
  @media (max-width: 594px) {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.48px;
    .sentence-break {
      display: block;
    }
  }
`;

const SpotLight = styled.div`
  position: absolute;
  width: 427px;
  height: 427px;
  border-radius: 427px;
  background: linear-gradient(180deg, #ff42f7 0%, #eeff416e 100%);
  filter: blur(160px);
  z-index: -1;
  top: 99px;
  left: 230px;
  @media (max-width: 968px) {
    width: 320.25px;
    height: 320.25px;
    top: 106.25px;
    left: 78.25px;
  }

  @media (max-width: 594px) {
    width: 281px;
    height: 267px;

    top: 197px;
    right: 31px;
  }
`;

const CalculatorWrapper = styled.div`
  border-radius: 24px;
  border: 1px solid rgba(255, 255, 255, 0.4);
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(160px);
  overflow: hidden;
  .range-wrapper,
  .value-wrapper {
    gap: 48px;
    justify-content: space-around;
  }
  @media (max-width: 968px) {
    .value-wrapper {
      gap: 35px;
      justify-content: space-around;
    }
  }
  @media (max-width: 594px) {
    padding: 0 16px;
  }
`;

const ContentWrapper = styled(FlexView)`
  padding: 56px 0px;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: 968px) {
    padding: 27px 0px;
  }
  @media (max-width: 594px) {
    padding: 40px 0px;
  }
`;

const Wrapper = styled(FlexView)`
  @media (max-width: 594px) {
    flex-direction: column;
  }
`;

const Line = styled.div`
  width: 1px;
  border: 0.5px solid rgba(255, 255, 255, 0.2);
  margin-top: 28px;
  margin-bottom: 28px;
  @media (max-width: 968px) {
    margin-top: 64px;
    margin-bottom: 8px;
  }
  @media (max-width: 594px) {
    width: 144px;
    margin: auto;
  }
`;

const TextWrapper = styled(FlexView)`
  gap: 16px;
  @media (max-width: 968px) {
    gap: 8px;
  }
  @media (max-width: 594px) {
    gap: 4px;
  }
`;

const BigText = styled.div`
  color: #fff;
  text-align: center;
  font-size: 40px;
  font-style: normal;
  font-weight: 900;
  line-height: 52px;
  letter-spacing: -0.8px;
  @media (max-width: 968px) {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.48px;
  }
`;

const Text = styled.div`
  color: #fff;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  @media (max-width: 968px) {
    font-size: 16px;
    line-height: 24px;
  }
  @media (max-width: 594px) {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.28px;
  }
`;

const DROPDOWN_OPTIONS = [
  { id: "education", value: "Education" },
  { id: "bfsi", value: "BFSI" },
  { id: "healthcare", value: "Healthcare" },
  { id: "it", value: "Information Technology (IT)" },
  { id: "manufacturing", value: "Manufacturing" },
  { id: "retail", value: "Retail" },
  { id: "transportatio_and_logistics", value: "Transportation and Logistics" },
  { id: "others", value: "Others" },
];

/*

50%	50%	4%
40%	300%	3%
50%	100%	5%
60%	50%	8%
40%	200%	5%
35%	100%	5%
40%	100%	5%
40%	100%	5%
*/

const INDUSTRY_CONFIG = {
  it: {
    factor1: 50,
    factor2: 50,
    factor3: 4,
  },
  manufacturing: {
    factor1: 40,
    factor2: 300,
    factor3: 3,
  },
  healthcare: {
    factor1: 50,
    factor2: 100,
    factor3: 5,
  },
  bfsi: {
    factor1: 60,
    factor2: 50,
    factor3: 8,
  },
  retail: {
    factor1: 40,
    factor2: 200,
    factor3: 5,
  },
  education: {
    factor1: 35,
    factor2: 100,
    factor3: 5,
  },
  transportatio_and_logistics: {
    factor1: 40,
    factor2: 100,
    factor3: 5,
  },
  others: {
    factor1: 40,
    factor2: 100,
    factor3: 5,
  },
};

export const DEFAULT_DROPDOWN_OPTIONS = {
  id: "it",
  value: "Information Technology (IT)",
};

export default function BenefitCalculator() {
  const [industry, setIndustry] = useState("it");
  let [employerCount, setEmployerCount] = useState(10000);
  let [averageEmployerSalary, setAverageEmployerSalary] = useState(50000);

  useEffect(() => {
    employerCount = 10000;
    // handleEmployerCountChange(10000);
    averageEmployerSalary = 50000;
    // handleAverageEmployerSalaryChange(50000);

    const values = calculateOutputValues(
      industry,
      employerCount,
      averageEmployerSalary
    );
    console.log("values :>> ", values);
    annualBenefit = values.annualBenefit;
    workingCapital = values.workingCapital;
    attritionCost = values.attritionCost;
  }, [
    industry,
    employerCount,
    averageEmployerSalary,
    setEmployerCount,
    setAverageEmployerSalary,
  ]);

  const handleEmployerCountChange = (value) => {
    setEmployerCount(value);
  };

  const handleAverageEmployerSalaryChange = (value) => {
    setAverageEmployerSalary(value);
  };

  const handleDropDownChanges = (value) => {
    setIndustry(value);
  };

  // takes industry, employee count and average employee salary as input
  // returns annual benefit, working capital and attrition cost in lakhs
  const calculateOutputValues = useCallback(
    (industry, employeeCount, averageEmployeeSalary) => {
      if (!industry)
        return { annualBenefit: 0, workingCapital: 0, attritionCost: 0 };
      console.log("INDUSTRY_CONFIG[industry] :>> ", INDUSTRY_CONFIG[industry]);
      let { factor1, factor2, factor3 } = INDUSTRY_CONFIG[industry];
      factor1 = factor1 / 100;
      factor2 = factor2 / 100;
      factor3 = factor3 / 100;
      const annualBenefit = employeeCount * averageEmployeeSalary * factor1;
      const workingCapital = employeeCount * averageEmployeeSalary * factor2;
      const attritionCost = employeeCount * averageEmployeeSalary * factor3;
      return {
        annualBenefit,
        workingCapital,
        attritionCost,
      };
    },
    []
  );

  const formatAndRoundCurrency = (amount) => {
    // Round to nearest thousand
    const roundedAmount = Math.round(amount / 1000) * 1000;

    // Format to Indian currency with 0 decimal places
    const formatter = new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });

    return formatter.format(roundedAmount);
  };

  let { annualBenefit, workingCapital, attritionCost } = calculateOutputValues(
    industry,
    employerCount,
    averageEmployerSalary
  );

  return (
    <Container>
      <Title>
        don’t believe us? <br className="sentence-break" />
        find out yourself
      </Title>
      <Separator height="40px" />
      <CalculatorWrapper>
        <SpotLight $color={"#FF42F7"} />
        <Wrapper $alignItems="stretch">
          <ContentWrapper className="range-wrapper">
            <FlexView
              $flexDirection="column"
              $alignItems="center"
              $justifyContent="center"
            >
              <Text>
                which industry does your <br />
                organisation belong to?
              </Text>
              <Separator height="16px" />
              <Dropdown
                options={DROPDOWN_OPTIONS}
                defaultItem={DEFAULT_DROPDOWN_OPTIONS}
                onChange={handleDropDownChanges}
              />
            </FlexView>
            <FlexView
              $flexDirection="column"
              $alignItems="center"
              $justifyContent="center"
            >
              <Text>
                how many employees does your <br />
                organisation have?
              </Text>
              <Separator height="12px" tabheight="8px" mobileheight="16px" />
              <InputBox
                className="yello-text"
                type="number"
                defaultValue={employerCount}
                value={employerCount}
                pattern="[0-9]*"
                onChange={(event) => {
                  const int = event.target.value;
                  const inputValue = parseInt(int).toString();

                  if (!isNaN(inputValue)) {
                    if (inputValue > 500000) return;
                    setEmployerCount(inputValue);
                  } else setEmployerCount(0);
                }}
              />
              <Separator height="12px" tabheight="4px" mobileheight="0px" />
              <InoutRange
                maxValue={500000}
                defaultValue={employerCount}
                onChange={handleEmployerCountChange}
              />
            </FlexView>
            <FlexView
              $flexDirection="column"
              $alignItems="center"
              $justifyContent="center"
            >
              <Text>
                what is the average monthly <br />
                salary in your organisation?
              </Text>
              <Separator height="12px" tabheight="8px" mobileheight="16px" />
              <InputBox
                className="yello-text"
                type="number"
                value={averageEmployerSalary}
                key="averageEmployerSalary"
                pattern="[0-9]*"
                onChange={(event) => {
                  const int = event.target.value;
                  const inputValue = parseInt(int).toString();

                  if (!isNaN(inputValue)) {
                    if (inputValue > 500000) return;
                    handleAverageEmployerSalaryChange(inputValue);
                  } else handleAverageEmployerSalaryChange(0);
                }}
              />
              {/* ₹{averageEmployerSalary} */}
              <Separator height="12px" tabheight="4px" mobileheight="0px" />
              <InoutRange
                maxValue={500000}
                defaultValue={averageEmployerSalary}
                onChange={handleAverageEmployerSalaryChange}
              />
            </FlexView>
          </ContentWrapper>
          <Line />
          <ContentWrapper className="value-wrapper">
            <TextWrapper $flexDirection="column" $alignItems="center">
              <BigText>{formatAndRoundCurrency(annualBenefit)}</BigText>
              <Text>annual benefits (paid by SalarySe)</Text>
            </TextWrapper>
            {/* <TextWrapper $flexDirection="column" $alignItems="center">
              <BigText>{manpowerCost}%</BigText>
              <Text>Manpower Cost Reduction Potential</Text>
            </TextWrapper> */}
            <TextWrapper $flexDirection="column" $alignItems="center">
              <BigText>{formatAndRoundCurrency(workingCapital)}</BigText>
              <Text>release of working capital</Text>
            </TextWrapper>
            <TextWrapper $flexDirection="column" $alignItems="center">
              <BigText>{formatAndRoundCurrency(attritionCost)}</BigText>
              <Text>savings in attrition cost</Text>
            </TextWrapper>
          </ContentWrapper>
        </Wrapper>
      </CalculatorWrapper>
    </Container>
  );
}
